import React, { useState } from 'react';
import styled from 'styled-components';
import { Transactions } from './carOwnerProfileTables/Transactions';
import { CallLogs } from './carOwnerProfileTables/CallLogs';
import { Requests } from './carOwnerProfileTables/Requests';

export const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: 'Transactions',
      content: <Transactions />
    },
    {
      label: 'Calls',
      content:<CallLogs />
    },
    {
      label: 'Requests',
      content: <Requests />
    }
  ];

  return (
    <Tab>
      <TabView>
        {tabs.map((item, index) => (
          <button
            key={index}
            className={`tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}>
            {item.label}
          </button>
        ))}
      </TabView>
      <TabContent>{tabs[activeTab].content}</TabContent>
    </Tab>
  );
};

const Tab = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2rem;
`;

const TabView = styled.div`
  height: 50px;
  display: flex;
  /* z-index: 10; */
  /* margin-left: -30px; */
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid #d9d9d9;

  button {
    padding: 12px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: #1eb85f;
    }
  }

  .active {
    color: #1eb85f;
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;