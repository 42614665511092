// utils.js
export const scrollToElement = (selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  
export const PeriodConstants  = {
  aYear:"12 months",
  aMonth:"30 days",
  aWeek:"7 days"
}

  export function GeneratePeriod(period,month){
    switch(true){
      case period === PeriodConstants.aYear: return generateYear()
      case period === PeriodConstants.aMonth: return generateMonth(month)
      case period === PeriodConstants.aWeek: return generateWeek()
    }
  }

  export function generateYear(){
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec']
  }
  export function generateWeek(){
    return ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
  }
  export function generateMonth(month){
    switch(true){
      case Number(month) === 8 : console.log("30 days"); return generateDayth(30)
      case Number(month) === 3 : console.log("30 days"); return generateDayth(30)
      case Number(month) === 5 : console.log("30 days"); return generateDayth(30)
      case Number(month) === 10 : console.log("30 days"); return generateDayth(30)
      case Number(month) === 1: console.log("28 days"); return generateDayth(28)
      default :  return generateDayth(31)
    }
  }

  function generateDayth(numODays){
    // console.log("numOdays",numODays);
    const numbers = ArrayLize(numODays)
    // console.log("numbers",numbers);
    const result = numbers.map(function(aVal){
        return  formatToDayth(aVal+1)
    });
    // console.log("result",result);
    return result;
  }

  function ArrayLize(number){
    const result = []
    for(let q= 0; q<number;q++){
          result.push(q)
    }
    return result
  }

  function formatToDayth(number){
    switch(true){
      case String(number).endsWith(1) === true: return `${number}st`
      case String(number).endsWith(2) === true: return `${number}nd`
      case String(number).endsWith(3) === true: return `${number}rd`
      default : return `${number}th`
    }
  }

  export function getTotalExpense(rawData,duration){
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getYear();
    let expense;
    switch(true){
      case duration === PeriodConstants.aMonth: {
        expense = rawData.filter(function(aTransaction){
          return new Date(aTransaction.date_created).getMonth() == currentMonth && aTransaction.transaction_type ==="fund"
      });
      }
      break
      case duration === PeriodConstants.aYear: {
        expense = rawData.filter(function(aTransaction){
          return new Date(aTransaction.date_created).getYear() == currentYear && aTransaction.transaction_type ==="fund"
      });
      }
      break
      case duration === PeriodConstants.aWeek: expense = getEachDayOfWeek(rawData)
      break
    }
    const Total = expense?.reduce(function(prev,next){
      return prev + (duration === PeriodConstants.aWeek ? next : next.amount)
    },0)
    return Total
  }

  export function getEachMonthsExpense(rawData =[],month){
    // console.log("rawData",rawData,"month",month);
    const monthExpenses = rawData.filter(function(aTransaction){
        return new Date(aTransaction.date_created).getMonth() == month && aTransaction.transaction_type ==="fund"
    });
    const monthTotal = monthExpenses.reduce(function(prev,next){
      // console.log("next",next.amount);
      return prev + next.amount
    },0)
    return monthTotal
  }

  export function getEachDayOfMonthExpense(rawData =[],day){
    // const currentMonth = 3
    const currentMonth = new Date().getMonth()
    const monthExpense = rawData.filter(function(aTransaction){
      return (new Date(aTransaction.date_created).getMonth() == currentMonth &&  aTransaction.transaction_type ==="fund") || 0
    })
    const dayExpense = monthExpense?.find(function(aTransaction){
      return ( new Date(aTransaction.date_created).getDate() == day  && aTransaction.transaction_type ==="fund") || 0
    });
    // console.log("month",currentMonth,"monthExpense",monthExpense,"day",day,"days expense",dayExpense?.amount);
    return dayExpense?.amount || 0
  }
  
  export function getEachDayOfWeek(rawData =[]){
    const month = new Date().getMonth();
    const day = new Date().getDay();
    const date = new Date().getDate();
    const result = [];
    const monthData = rawData.filter(function(aTransaction){
      return new Date(aTransaction.date_created).getMonth() == month
    });

    result[day] = monthData.find(function(aTransaction){
         return (new Date(aTransaction.date_created).getDate() == date) || 0;
    })?.amount || 0

    for(let i = 0 ; i<day ; i++){
      result[i] = monthData.find(function(aTransaction){
        return (new Date(aTransaction.date_created).getDate() == date - day + i) ;
   })?.amount || 0;
    }
    for(let i = day+1 ; i< 7 ; i++){
      result[i] = monthData.find(function(aTransaction){
        return (new Date(aTransaction.date_created).getDate() == date + i);
   })?.amount || 0;
    }
    
    // console.log("day",day);
    // console.log("date",date);
    // console.log("month",month);
    // console.log("monthData",monthData);
    console.log("result",result);
    return result;
  }

  export function TextError (props) {
    return <div className="sharp-sans font-bold text-red-600 text-[11px] !mt-2 w-full max-w-[300px]">{props.children}</div>;
  }

  export function numberWithCommas(n) {
    const num = Number(parseFloat(n).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2
    });
    return num;
  }