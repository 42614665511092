import axios from "axios";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

//endpoint for the user login 
export default class AuthService {
    static async loginUser (data) {
        const res = await axios.post(`${baseUrl}
/user/login/`, data);
        // console.log(res.data, "resss.data");
        return res?.data || res;
    }

    static async rememberMeLogin (data, accessToken) {
        const res = await axios.post(
            `${baseUrl}
/user/login/`, 
            data,
            {
                headers:{
                    Authorization:`Bearer ${accessToken}`
                }
            }
        );
        // console.log(res.data, "resss.data");
        return res?.data || res;
    }
    static async verifyOtp (data) {
        const res = await axios.post(`${baseUrl}
/user/verify-otp/`, data);
        return res?.data || res;
    }
    static async resendOtp (data) {
        const res = await axios.post(`${baseUrl}
/user/send-otp/`, data);
        return res?.data || res;
    }
}
