import {MeetingProvider, MeetingConsumer, useMeeting, useParticipant,} from "@videosdk.live/react-sdk";
import { AudioControls,AudioControls2,Counter,Biodata, AudioMents } from "./components"
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowLeft2 } from "iconsax-react"
import axios from "axios";
import { createMeeting } from "./components";
import { duration } from "@mui/material";
import { useMediaDevice } from "@videosdk.live/react-sdk";

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNjY3OTg2NywiZXhwIjoxNzU4MjE1ODY3fQ.o9w3qWNc3Y6caNnq5u6WAsc4Vx1l8sC6Pow9hhZtRnQ"
const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function AudioCallz(){
const [startTimer,setStartTimer] = useState(false);
const [searchParams, setSearchParams] = useSearchParams();
const [meetingId, setMeetingId] = useState(null);
const [call_id, setCall_id] = useState();
const [callDuration, setCallDuration] = useState(null);
const id = null;
const mechanic_id = searchParams.get("mechanic_id");
const token = searchParams.get("token")
const name = searchParams.get("name")
const image = searchParams.get("image")
const model = searchParams.get("model")
const [notifcationPayload, setnotificationPayload] = useState({ownerId:"",
                                                               ownerName:"",
                                                               meeting_id:'',
                                                               call_token:'',
                                                               owner_fcm:"",
                                                               fcm_token:"",
                                                               is_call:false,
                                                               is_voice:false,
                                                               initiate_id:"",
                                                               owner_image:"",
                                                               email:"",
                                                               duration:"",
                                                               model:""})
const {requestPermission} = useMediaDevice()
function onMeetingLeave(){
  setMeetingId(null);
};

function handleBack(){
    window.history.back();
}

useEffect(function(){
  requestPermission("audio_video")
},[])

useEffect(function(){
  
  const meetingId = (id == null) ?  createMeeting(authToken).then(function(response){
    console.log("meeting id gotten");
    return setMeetingId(init=>response);
  }) : setMeetingId(init=>id);
},[]);

useEffect(function(){
    const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
      if (meetingId !== null) {
            axios.post(`${baseUrl}/calls/initiate/`,{
              mechanic_id:mechanic_id,
              room_id:meetingId
            },{
              headers:{
                'Authorization':`Bearer ${accessToken}`,
                'Content-Type':'application/json'
              }
            }).then(function(response){
              console.log("initiate call response",response?.data?.id);
                setCall_id(init=>response?.data?.id);
                setCallDuration(init=>response?.data?.expected_call_duration);
                return response
            }).catch(function(error){
              console.log(error);
            })
      } 
    }
},[meetingId])

useEffect(function(){
const userEmail = localStorage.getItem("userEmail");
const firstname = localStorage.getItem("firstName")
const lastname = localStorage.getItem("lastName")
const ownerToken = localStorage.getItem("deviceToken")
const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
        axios.get(`${baseUrl}/user/user/${userEmail}/`,{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
        }).then(function(response){
          if(meetingId != null && callDuration != null){
            if(userEmail){
              setnotificationPayload(init=>{return {
              ownerId:response?.data?.data?.id,
              ownerName:`${firstname} ${lastname}`,
              meeting_id:meetingId,
              call_token:authToken,
              owner_fcm:ownerToken,
              fcm_token:token,
              is_call:true,
              is_voice:true,
              initiate_id:call_id,
              owner_image:response?.data?.data?.profile_pic,
              email:userEmail,
              duration:duration,
              model:model
            }})
              console.log("notification payload created");
            }
          }
        }).catch(function(error){
          console.log(error);
        })
    }

},[callDuration])

useEffect(function(){
  const accessToken = localStorage.getItem('authAccessToken');
  const formattedValue =  JSON.stringify(notifcationPayload)

if(notifcationPayload.ownerId !=""){
  console.log("about to create notification payload");
  // handle notification with backend endpoint
      axios.post(`${baseUrl}/client/send-message/`,{
        ...notifcationPayload
    },{
        headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
        }
    }).catch(function(error){
      console.log("notification endpoint call error",error);
    })
}

},[notifcationPayload])

    return <div className="w-screen h-screen p-4">
                {(authToken !=null && meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: false, name:name,}} token={authToken}>
                <div className="w-full" onClick={handleBack}><ArrowLeft2/></div>
                <div className="w-full h-[75%] flex flex-col justify-end gap-[8rem] items-center">
                    <div className="flex flex-col gap-[2rem] justify-center  items-center">
                        <Biodata  carmodel={model} image={image} name={name}/>
                        {startTimer ? <Counter/>:<p>Calling....</p>}
                    </div>
                    <AudioControls2  name={name} room_id={meetingId} mechanicToken={token} />
                </div>
                <AudioMents room_id = {meetingId} call_id={call_id} stopTimer={function(){setStartTimer(init=>false)}} startTimer={function(){setStartTimer(init=>true)}}/>
                </MeetingProvider>)}
    </div>
}
