
import { motion } from "framer-motion";
import heroImg1 from "../Images/1 1.png";
import heroImg2 from "../Images/image 5.png";
import heroImg3 from "../Images/image 6.png";
import heroImg4 from "../Images/image 8.png";
import cariMg from "../Images/car.png";
import useMeasure from "react-use-measure"



export default function CarDrive(){
    const [containerRef, containerBound] = useMeasure();
    // const animation = {
    //     initial:{
    //         translateY:[`-${carImageBound.height/2}px`],
    //         translateX:["0%"],
    //         rotate:["0deg"]
    //     },
    //     animation:{
    //         translateY:[`-${carImageBound.height/2}px`,
    //         `-${carImageBound.height/2}px`,
    //         `${containerBound.height - (carImageBound.height/2)}px`,
    //         `${containerBound.height - (carImageBound.height/2)}px`,
    //         `-${carImageBound.height/2}px`],

    //         translateX:["0px",
    //         `${containerBound.width -(carImageBound.width/2)}px`,
    //         `${containerBound.width -(carImageBound.width/2)}px`,
    //         `-${carImageBound.width/2}px`,
    //         `-${carImageBound.width/2}px`],

    //         rotate:["0deg","90deg","180deg","270deg","360deg"],
            
    //         transition:{
    //             duration:10,
    //                 rotate:{times:[0,0.35,0.6,0.8,0.99],duration:10,repeat:Infinity,ease:"anticipate"},
    //                 repeat:Infinity,
    //                 times:[0,0.2,0.5,0.7,0.94],
    //         }
    //     }
    // }

    return <motion.div className="relative grid grid-cols-2 gap-4 border-1 border-dashed p-4 rounded" ref={containerRef} >
                <img src={heroImg1} alt="" className="w-full h-full rounded object-contain" />
                <img src={heroImg2} alt="" className="w-full h-full rounded object-contain" />
                <img src={heroImg3} alt="" className="w-full h-full rounded object-contain" />
                <img src={heroImg4} alt="" className="w-full h-full rounded object-contain" />
                <motion.img src={cariMg} id = "car" alt="Car illustration" className="absolute"  />
            </motion.div>
}