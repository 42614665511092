import {
  HambergerMenu,
  Profile,
  Edit,
  Wallet,
  Message,
  Clipboard,
  Security,
  Logout,
} from "iconsax-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import axios from "axios";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 

export default function Menu({ name, car, image }) {
  const [showMenuBody, setShowMenuBody] = useState(false);
  const handle = useSwipeable({
    onSwipedLeft: (eventData) => {
      console.log("swipe left");
      openMenu();
    },
    onSwipedRight: (eventData) => {
      console.log("swipe left");
      closeMenu();
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  function openMenu() {
    setShowMenuBody((init) => true);
  }

  function closeMenu() {
    setShowMenuBody((init) => false);
  }

  return (
    <div className="absolute top-0 left-0 z-20 w-0 h-0">
      <MenuIcon handleClick={openMenu} />
      <MenuBody
        image={image}
        closeMenu={closeMenu}
        slideMenu={showMenuBody}
        name={name || "user"}
        car={car}
        links={[
          { title: "Wallet", icon: Wallet, url: "/wallet" },
          { title: "Get Help", icon: Message, url: "/user-help" },
          { title: "Legal", icon: Clipboard, url: "/legal" },
          { title: "Privacy Policy", icon: Security, url: "/" },
          { title: "About us", icon: Profile, url: "/" },
        ]}
      />
    </div>
  );
}

function MenuIcon({ handleClick }) {
  return (
    <div
      onClick={handleClick}
      className="absolute top-6 left-6 bg-slate-200 rounded-full p-2 "
    >
      <HambergerMenu className="text-black" />
    </div>
  );
}

function MenuBody({ links = [], name, car, slideMenu, closeMenu, image }) {
  const bodyRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handle = useSwipeable({
    // onSwipedLeft:(eventData)=>{ console.log("swipe left"); openMenu()},
    onSwipedUp: (eventData) => {
      console.log("swiping");
    },
    onSwipedRight: (eventData) => {
      console.log("swipe left");
      closeMenu();
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
    onTap: () => {
      console.log("tabbeed");
    },
  });
  const slideAnimation = {
    init: {
      left: "-101vw",
    },
    slideOut: {
      left: "0vw",
      transition: {
        duration: 1,
      },
    },
    slideIn: {
      left: "-101vw",
      transition: {
        duration: 1,
      },
    },
  };

  function handleClick(e) {
    e.currentTarget == e.target && closeMenu();
  }

  const logout = async () => {
    const accessToken = localStorage.getItem("authAccessToken");
    const refreshToken = localStorage.getItem("authRefreshToken");
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/user/logout/`,
        { refresh: refreshToken },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      if (response.status === 200) {
        console.log("Logout successful");
        localStorage.clear();
        navigate("/login-user");
      } else {
        console.error("Logout failed with status: ", response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      onClickCapture={(e) => handleClick(e)}
      variants={slideAnimation}
      initial="init"
      animate={slideMenu ? "slideOut" : "slideIn"}
      className="top-0 left-0 w-screen h-screen  relative"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="  w-[85%] h-screen bg-slate-200 flex flex-col gap-8"
      >
        <div className="flex w-full justify-between px-4 py-8 items-center">
          <div className="flex gap-4 ">
            <div
              className={`w-[15vw] h-[15vw] ${
                image === null && "bg-teal-500 p-1"
              }  overflow-hidden rounded-full  box-border  `}
            >
              {image !== null ? (
                <img className="" src={image} />
              ) : (
                <Profile variant="Bulk" className="text-slate-600" />
              )}
            </div>
            <div>
              <p className="font-semibold">{name}</p>
              {!String(car).includes("null") && (
                <p className="text-sm">{car}</p>
              )}
            </div>
          </div>
          <Link to={"/profile"}>
            <Edit />
          </Link>
        </div>
        {links.map(function (ALink) {
          return (
            <div>
                <Link className="flex gap-4 px-4 font-semibold" to={ALink.url}>
                  <ALink.icon />
                  {ALink.title}
                </Link>
            </div>
          );
        })}
        <LogoutView>
            <Logout />
            <button
                onClick={() => logout()}
            >
                {loading ? "Logging out..." : "Logout"}
            </button>
        </LogoutView>
      </div>
    </motion.div>
  );
}


const LogoutView = styled.div`
    gap: 1.5rem;
    display: flex;
    margin-left: 1.5rem;

    button {
        font-weight: 600;
    }
`;