
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Wallet } from "iconsax-react";

export default function LowBalance({handleCancel}){
    const navigate = useNavigate();
    function handleFundWalletClick(){
        navigate('/topup')
    }
    return <div className="z-[4] justify-around px-8 py-4 absolute top-[-30vh] left-[4.5%] w-[90%] h-[45vh] bg-slate-100 rounded-3xl flex flex-col items-center">
                <div className="w-[6rem] h-[6rem] bg-gradient-to-br from-[1%] to-green-600 from-slate-100  rounded-full flex items-center  justify-center text-white">
                    <Wallet className="w-[2.5rem] h-[2.5rem] " variant="TwoTone" />
                </div>
                <div className="flex flex-col px-8 w-full gap-2">
                    <p  className=" font-sharp font-extrabold text-2xl text-center">
                        Oops! You Need to Fund Your Account
                    </p>
                    <p className="text-slate-500 text-center text-sm">
                        Oops it looks like there's not enough funds in you wallet at the moment.
                    </p>
                </div>
                <div className="flex w-full justify-between ">
                    <Button onClick={handleFundWalletClick} sx={{textTransform:"none"}}  variant="contained" style={{backgroundColor:"rgb(74 222 128)",fontSize:"1rem",fontWeight:"600",fontFamily:"SharpSans"}} className=" bg-green-400 text-white flex gap-2 p-3 w-[47%] font-sharp">Fund Wallet</Button>
                    <Button onClick={()=>handleCancel(init=>false)} sx={{textTransform:"none"}} className=" text-black flex  w-[47%]" variant="outlined" style={{borderColor:"rgb(22 163 74)",fontSize:"1rem",fontWeight:"600",fontFamily:"SharpSans"}}>Cancel</Button>
                </div>


    </div>
}