import { FiMenu } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { useState } from "react";
import { motion } from "framer-motion";

const navlist = [
    {
      title: "Home",
      link: "/"
    },
    {
      title: "Service",
      link: "#services"
    },
    {
      title: "Support",
      link: "#support"
    },
    {
      title: "FAQs",
      link: "#FAQs"
    }
  ]

export default function MobileMenu(){
    const [isOpen,setIsOpen] = useState(false);
    const iconAnimation = {
      initial:{
        transform : "rotateZ(0deg)"
      },
      spin:{
        transform : "rotateZ(90deg)",
        transition:{
          duration:0.3,
        }
      }
    }

    function toggleDrawer(){
        setIsOpen(function(init){
            return !init
        })
    }
    return <div id="MobileMenuContainer" className = " md:hidden landscape:hidden"       >
    <motion.div variants={iconAnimation} initial="initial" animate={isOpen?"spin":""}>
        <FiMenu  style={{color:"#6BFDCB",height: "1.5rem", width: "1.5rem" }} onClick={toggleDrawer} />
    </motion.div>
      <Drawer isOpen={isOpen} closeDrawerfunc={toggleDrawer}/>
  </div>
}



function Drawer({navigationList = navlist,isOpen,closeDrawerfunc}){
const path = useLocation().pathname;
const animation = {
  initial:{
    top:0,
    left:0,
    transform:"translateX(-100vw)",
  },
  slideOut:{
    transform:"translateX(-1vw)",
    transition:{
      duration:0.5,
      delay:0.3
    }
  }
}
    return <motion.div className={`w-screen h-screen absolute bg-black opacity-60i top-0  flex flex-col pt-8 `} variants={animation} initial="initial" animate={isOpen?"slideOut":""} >
                <div className="pr-8 w-fit self-end">
                    <FiX style={{color:"#6BFDCB", width:"3rem",height:"3rem", cursor: "pointer"}} onClick={closeDrawerfunc}/>
                </div>
                <motion.div className="flex flex-col items-center justify-center gap-12 pt-20 h-[70vh] " >
                    {navigationList.map((nav) => {
                    return (path !== nav.link) && <a key={nav.title} href={nav.link} className="text-gray-400 hover:text-white  no-underline text-4xl" onClick={closeDrawerfunc} >{nav.title}</a>
                    })}
                </motion.div>
    </motion.div>
}