import { ArrowRight2, ArrowLeft2 } from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import { TermsModal } from "../Components/modal/termsModal";
import { useEffect, useState } from "react";

export default function Legal() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [contractType, setContractType] = useState("");

  const openModal = (mType, contractType) => {
    setIsModalOpen(true);
    setModalType(mType);
    setContractType((init) => contractType);

    if (mType === "pp") {
      navigate("#privacy-policy");
    } else if (mType === "tnc") {
      navigate("#terms-and-condition");
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalType("");
    setContractType((init) => "");
    navigate(window.location.pathname);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#privacy-policy") {
      openModal("pp");
    } else if (hash === "#terms-and-condition") {
      openModal("tnc");
    }
  }, []);

  function handleBack() {
    window.history.back();
  }

  return (
    <div>
      <div className="w-screen h-screen px-7 py-4">
        <p className="mb-10 font-bold flex gap-3 text-2xl items-center">
          <ArrowLeft2 onClick={handleBack} className="font-normal" />
          Legal
        </p>
        <div className="flex flex-col gap-6">
          <div onClick={() => setIsModalOpen(true)} ><Sub title={"Privacy Policy"} /></div>
          <div onClick={() => setIsModalOpen(true)} ><Sub title={"Terms of Use"} /></div>
        </div>
      </div>

      <TermsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        modalType={modalType}
        userType={contractType}
      />
    </div>
  );
}

function Sub({ title, Icon1 }) {
  return (
    <div className="flex justify-between">
      <p className="flex gap-2 justify-between">
        {Icon1 && <Icon1 />}
        {title}
      </p>
      <div className='cursor-pointer'>
        <ArrowRight2 />
      </div>
    </div>
  );
}
