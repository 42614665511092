import { createSlice } from "@reduxjs/toolkit";
import { getCarBrands, getMechanicServices } from "../actions/userAction";

const initialState = {
    carBrands: [],
    mechanicServices: [],
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCarBrands.fulfilled, (state, action) => {
            return { ...state, carBrands: action.payload.data };
          });
        builder.addCase(getMechanicServices.fulfilled, (state, action) => {
            return { ...state, mechanicServices: action.payload.data };
          });
    },

    
});

const { reducer } = userSlice;

export default reducer;
