import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopHeader from './TopHeader';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Wrench from "./wrenchsvg";
import { Head } from './transactionHistory';

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

const Mechanics = ({ userEmail }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [registeredMechanics, setRegisteredMechanics] = useState([]);
  const [totalRegisteredMechanics, setTotalRegisteredMechanics] = useState(0);
  const [processedData, setProcessedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem('authAccessToken');

    if (accessToken) {
      axios
        .get(`${baseUrl}
/user/users/mechanics/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log('Registered Mechanics Response:', response.data);

          const mechanicsData = response.data?.data || [];
          setRegisteredMechanics(mechanicsData);
          setTotalRegisteredMechanics(mechanicsData.length);
        })
        .catch((error) => {
          console.error('Error fetching registered mechanics data:', error);
        });
    } else {
      console.error('Authentication credentials were not provided.');
    }
  }, []);

  useEffect(() => {
    if (registeredMechanics.length > 0) {
      const newData = registeredMechanics.filter((aRegisteredMechanic) => {
        return String(`${aRegisteredMechanic.first_name} ${aRegisteredMechanic.last_name} ${aRegisteredMechanic.email} ${aRegisteredMechanic.phone_number} ${aRegisteredMechanic.car_speciality.map((specialty) => specialty.name).join(' ')}`).toLowerCase().includes(searchValue.toLowerCase());
      });
      setProcessedData(newData);
    }
  }, [searchValue, registeredMechanics]);

  function handleSearchChange(value) {
    setSearchValue(value);
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="overall-dashboard-div flex h-screen bg-white-100">
      <button className="hamburger-icon" onClick={toggleSidebar}>
        <FiMenu className="hamburger" />
      </button>

      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

      <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
        <div className={`dashboard-content ${showSidebar ? 'sidebar' : ''}`}>
          {showSidebar && (
            <button className="close-sidebar-button" onClick={toggleSidebar}>
              <FiX className="close-icon" />
            </button>
          )}
          <TopHeader userEmail={userEmail} />

          <div className="p-8">
            <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
              Users {'>'} Mechanics
            </h2>
            <p className="dashboard-text mt-2 text-gray-400">
              Track and manage the total number of registered mechanics on the platform
            </p>
          </div>

          <div className="dashboard-stats grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 ml-8 mr-8">
            <div className="dashboard-stat-container rounded-lg border-gray-100 border p-4 shadow-md">
              <div className="dashboard-stat-icon bg-orange-300 rounded-full p-3 w-fit flex items-center justify-center">
                  <Wrench className='text-3xl text-orange-600'/>
              </div>
              <h3 className="mt-2 text-sm font-semibold text-slate-500">Total number of registered mechanics</h3>
              <p className="mt-2 text-2xl font-bold">{totalRegisteredMechanics}</p>
            </div>
          </div>

          <div className="mt-4">
            <div className='px-4'>
                <Head title={"Users"} handleSearch={handleSearchChange} placeholder={"Search Mechanic by Name, Email, speciality or Phone Number "} />
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-4 text-left">Mechanic Name</th>
                    <th className="p-4 text-left">Specialty</th>
                    <th className="p-4 text-left">Profile</th>
                  </tr>
                </thead>
                <tbody>
                  {processedData.map((mechanic) => (
                    <tr key={mechanic.id} className="bg-white">
                      <td className="p-4">{(mechanic.first_name || 'Unknown') + ' ' + (mechanic.last_name || '')}</td>
                      <td className="p-4">
                        {(mechanic.car_speciality && mechanic.car_speciality.length > 0) ?
                          mechanic.car_speciality.map((specialty) => specialty.name).join(', ') :
                          'Unknown'}
                      </td>
                      <td className="p-4">
                        <Link to={`/mechanics/${encodeURIComponent(mechanic.email)}`} className="text-blue-500">
                          View Profile
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mechanics;
