import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopHeader from './TopHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faUser, faWrench, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Tabs } from './Tabs';


const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

const CarOwnerProfile = ({ userEmail }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [carOwnerData, setCarOwnerData] = useState(null);
  // const [mechanicDatax, setMechanicDatax] = useState(null);
  const { email } = useParams(); // Assuming you're using the email as a parameter
  const [errorMessage, setErrorMessage] = useState(''); // State to store error message
  const [successMessage, setSuccessMessage] = useState(''); // State to store success message
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  useEffect(() => {
    const accessToken = localStorage.getItem('authAccessToken');

    if (accessToken) {
      axios
        .get(`${baseUrl}/user/user/${encodeURIComponent(email)}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          setCarOwnerData(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching car-owner data:', error);
        });
    } else {
      console.error('Authentication credentials were not provided.');
    }

  }, [email]); // Include userEmail in dependencies

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <div className="overall-dashboard-div flex h-screen bg-white-100">
        <div className="hamburger-icon" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} className="hamburger" />
        </div>

        <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

        <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
          <div className={`dashboard-content ${showSidebar ? 'sidebar' : ''}`}>
            {showSidebar && (
              <div className="close-sidebar-button" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faTimes} className="close-icon" />
              </div>
            )}
            <TopHeader userEmail={userEmail} />

            <div className="p-8">
              <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
                Users {'>'} <Link to="/mechanics">Car Owners</Link> {'>'} {carOwnerData ? carOwnerData.first_name + ' ' + carOwnerData.last_name : 'Loading...'}
              </h2>
              <p className="dashboard-text mt-2 text-gray-400">
                Track and manage the total number of registered car-owners on the platform.
              </p>
            </div>

            <h1 className="text-lg font-semibold text-gray-800 ml-8">Car Owner's Profile</h1>

            {carOwnerData && (
              <div className="flex flex-col md:flex-row justify-between px-8 mt-8 gap-y-3">
                <div className="bg-white border border-gray-500 p-4 rounded-lg w-full md:w-1/2">
                  <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                    <FontAwesomeIcon icon={faUser} className="text-2xl text-white" />
                  </div>
                  <div className="text-gray-800 mt-4">
                    <h3 className="text-lg font-bold text-gray-800">Personal Information</h3>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold capitalize">First Name: {carOwnerData.first_name}</span> 
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold capitalize">Last Name: {carOwnerData.last_name}</span> 
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Email Address:</span> {carOwnerData.email}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Date of Birth:</span> {carOwnerData.date_of_birth}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Phone Number:</span> {carOwnerData.phone_number}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Car Brand</span> {carOwnerData.car_brand}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-1/2 md:ml-4 gap-3">
                  <div className="bg-white border border-gray-500 p-4 rounded-lg">
                    <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                      <FontAwesomeIcon icon={faWrench} className="text-2xl text-white" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800">Car Speciality</h3>
                    <div className="flex space-x-4 flex-wrap">
                      {carOwnerData?.car_speciality?.map((speciality) => (
                        <div key={speciality.id} className=" rounded-md bg-gray-200 p-2 m-1 text-xs  ">{speciality.name}</div>
                      ))}
                    </div>
                </div>

                <div className="flex flex-col w-full  gap-3">
                  <div className="bg-white border border-gray-500 p-4 rounded-lg">
                    <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center"><FontAwesomeIcon icon={faWrench} className="text-2xl text-white" /></div>

                    <h3 className="text-xl font-semibold text-gray-800">Services</h3>

                    <div className="flex space-x-4 flex-wrap">
                      {carOwnerData?.services?.map((speciality) => (
                        <div key={speciality.id} className=" rounded-md bg-gray-200 p-2 m-1 text-xs  ">{speciality.name}</div>
                      ))}
                    </div>

                </div>
                </div>

                  <div className="bg-white border border-gray-500 p-4 rounded-lg">
                    <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                      <FontAwesomeIcon icon={faFileAlt} className="text-2xl text-white" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800">Uploaded Document</h3>
                    <a
                      href={carOwnerData.uploaded_document?.document_url} // Assuming 'supported_document' is the URL of the document
                      download // This attribute indicates that the resource should be downloaded when clicked
                      className="mt-2 text-blue-500 hover:underline cursor-pointer" // Style the link as needed
                    >
                      Download Document
                    </a>
                  </div>

                </div>
              </div>
            )}

              <Tabs />
            <div className="flex justify-start mt-8 ml-8 pr-8">

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default CarOwnerProfile;
