

const OnboardClientCard = ({text1, text2, text3, text4, text5, image}) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
    };
    return (
        <div className="">
            <div className="-mt-[20px] mb-[130px] w-auto h-[236px] md:flex md:justify-center">
                <img src={require(`../Images/${image}`)}/>
            </div>
            <div className=" space-y-[20px]">
                <div className="leading-[44.75px]">
                    <p className=" text-[38px] text-center sharp-sans-bold font-bold">{text1}</p>
                    <p className="text-[38px] text-center sharp-sans-bold font-bold">{text2}</p>
                </div>
                <div className="leading-[#28.2px] pb-3"> 
                    <p className="text-[#878C98] text-[18.8px] text-center">{text3}</p>
                    <p className="text-[#878C98] text-[18.8px] text-center">{text4}</p>
                    <p className="text-[#878C98] text-[18.8px] text-center">{text5}</p>
                </div>  
            </div>
           
        </div>
        
        
   
    );
  }
  
  export default OnboardClientCard;