import ProfileTypeCard from "../Components/profileTypeCard";
import Button from "../Components/button";
import { Link } from "react-router-dom";
import { useState } from "react";

const ProfileType = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const cardDetails = [
    {
      id: 1,
      path: '/mechanic-create-account1',
      title: "Mechanic",
      text1: "Join as a certified mechanic and connect",
      text2: "with car owners in need of expert help",
      image: "mechanic.png",
    },
    {
      id: 2,
      path: '/carowner-create-account1',
      title: "Car Owner",
      text1: "Get quick and reliable car repair services",
      text2: "with our virtual mechanic matching system",
      image: "car2.png",
    },
  ];

  const handleCardClick = (id) => {
    setSelectedCard(id);
  };

  const selectedCardPath = selectedCard
    ? cardDetails.find(card => card.id === selectedCard)?.path
    : '';

  return (
    <div className="flex flex-col items-center sharp-sans">
      <div className="flex flex-col justify-between h-screen">
        <div className="mt-7 space-y-3">
          <h3 className="text-2xl text-black sharp-sans-bold">
            Select your profile type
          </h3>
          <div className="flex flex-col gap-y-5">
            <div className="min-w-[330px] mb-2">
              {cardDetails.map((card) => {
                return (
                  <div
                    className={`p-3 gap-x-3 flex sharp-sans mb-3`}
                    style={{
                        borderColor: selectedCard === card.id ? 'green' : '',
                        borderWidth: '1.45px',
                        borderStyle: 'solid',
                        borderRadius: '5px'
                      }}
                    key={card?.id}
                    onClick={() => handleCardClick((card?.id))}
                  >
                    <div className="mt-[6px] bg-[#84FDD4] flex items-center justify-center rounded-[50px]  w-[38px] h-[38px]">
                      <img src={require(`../Images/${card?.image}`)} />
                    </div>
                    <div className="">
                      <p className="text-base sharp-sans-bold">{card?.title}</p>
                      <p className="text-[#757575] text-xs">{card?.text1}</p>
                      <p className="text-[#757575] text-xs">{card?.text2}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 pb-3">
          <Link to={selectedCardPath}>
            <Button text="Continue" className=" text-white bg-greeen" />
          </Link>
          <p className="text-center font-[500] text-xs">
            Already have an account?{" "}
            <Link to="/login-user">
              <span className="text-greeen sharp-sans-bold">Log In</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileType;
