import { CustomerPP } from "../terms-privacy-conditions/privacy-policy/customer-pp";
import { PP } from "../terms-privacy-conditions/privacy-policy/pp";
import { CustomerTnC } from "../terms-privacy-conditions/terms-conditions/customerTnC";
import { TnC } from "../terms-privacy-conditions/terms-conditions/tnc";


export const TermsModal = ({ isOpen, onClose, modalType,userType }) => {
    const contractTypeConstant = {
        Mechanic:"mechanic",
        Customer:"Customer"
    }

    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 p-4 md:p-14 z-50 flex justify-center items-center">
        <div className="bg-white text-black p-6 md:p-16 max-w-full md:max-w-5xl max-h-full overflow-y-auto mx-auto rounded shadow-lg">
          <h2 className="text-xl font-semibold mb-4">
            {modalType === "tnc" ? "Terms & Conditions" : "Policy Privacy"}
          </h2>
          <div className="text-xs w-full text-left">
            {modalType === "tnc" ? (userType === contractTypeConstant.Mechanic ? <TnC />:<CustomerTnC/>) : (userType === contractTypeConstant.Mechanic ? <PP />:<CustomerPP/>)}
          </div>
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition ease-in-out duration-150"
          >
            I Agree
          </button>
        </div>
      </div>
    );
  };