import React, { useState, useEffect, useRef } from "react";
import {
  FiHome,
  FiDollarSign,
  FiPhoneCall,
  FiUsers,
  FiLogOut,
  FiMenu,
  FiChevronRight,
} from "react-icons/fi";
import { IoMdGitPullRequest } from "react-icons/io";

import logoImg from "../Images/RM - Idan-03 1.svg";
import logoTxt from "../Images/Request Mechanic.png";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import mixpanel from "mixpanel-browser";


const Sidebar = ({ showSidebar, toggleSidebar }) => {
  return (
    <>
      <LandscapeSideBar />
      <MobileSideBar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
    </>
  );
};

function LandscapeSideBar() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLogout = async () => {
    try {
      // Retrieve refresh token from local storage
      const refreshToken = localStorage.getItem("refreshToken");
      console.log("Refresh Token:", refreshToken);

      // Check if refresh token is available
      if (!refreshToken) {
        console.error("Refresh token not found.");
        setError("Logout failed. Refresh token not found.");
        return;
      }

      // Make a request to the refresh endpoint
      const refreshResponse = await axios.post(
        "http://164.92.83.167:8080/v1/user/token/refresh/",
        {
          refresh: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Refresh Response Status Code:", refreshResponse.status);
      console.log("Refresh Response Data:", refreshResponse.data);

      // Assuming a successful refresh, clear local storage and navigate to login page
      if (refreshResponse.status === 200) {
        localStorage.removeItem("authAccessToken");
        localStorage.removeItem("refreshToken");

        const newAccessToken = refreshResponse.data.access;

        // Save the new access token in local storage

        localStorage.setItem('authAccessToken', newAccessToken);
        mixpanel.reset()
        navigate('/login');

        localStorage.setItem("authAccessToken", newAccessToken);

        navigate("/login");

      } else {
        console.error("Refresh failed:", refreshResponse.data.message);
        setError("Logout failed. Please try again.");
      }
    } catch (error) {
      console.error("Refresh failed:", error);
      setError("Logout failed. Please try again.");
    }
  };

  return (
    <motion.div id="landscape" className="sidebar">
      <div className="w-full h-full gap-9 flex flex-col">
        <div className="logor flex-shrink-0 flex items-center gap-2 pb-8 md:pb-0">
          <img className="w-8" src={logoImg} alt="Request Mechanic" />
          <img className="w-16" src={logoTxt} alt="" />
        </div>

        {/* Desktop Sidebar Links */}
        <ul className=" mt-8 w-full min-h-[50vh] gap-[5vh] landscape:gap-0 landscape:h-auto flex flex-col ">
          <Link to="/dashboard" className="no-underline w-full">
            <li className="flex w-full items-center py-1 mb-4 justify-between text-white md:gap-0 text-xl md:text-sm">
              <FiHome className="sidebar-icon  w-[20%] h-4" />
              <span className="sidebar-text w-[75%] overflow-hidden text-ellipsis whitespace-nowrap ">
                Dashboard
              </span>
            </li>
          </Link>

          <Link to="/transactions" className="no-underline">
            <li className="flex items-center py-1 mb-4 text-white md:gap-0 text-xl md:text-sm">
              <FiDollarSign className="sidebar-icon w-[20%] h-4" />
              <span className="sidebar-text w-[75%] overflow-hidden text-ellipsis whitespace-nowrap ">
                Transactions
              </span>
            </li>
          </Link>
          <Link to="/call-logs" className="no-underline">
            <li className="flex items-center py-1 mb-4 text-white md:gap-0 text-xl md:text-sm">
              <FiPhoneCall className="sidebar-icon w-[20%] h-4" />
              <span className="sidebar-text w-[75%] overflow-hidden text-ellipsis whitespace-nowrap ">
                Call Logs
              </span>
            </li>
          </Link>
          <Link to="/requests" className="no-underline">
            <li className="flex items-center py-1 mb-4 text-white md:gap-0 text-xl md:text-sm">
              <IoMdGitPullRequest className="sidebar-icon w-[20%] h-4" />
              <span className="sidebar-text w-[75%] overflow-hidden text-ellipsis whitespace-nowrap ">
                Requests
              </span>
            </li>
          </Link>

          <UsersChevron />
        </ul>
      </div>
      <div
        className="logout mt-auto flex cursor-pointer"
        onClick={handleLogout}
      >
        <FiLogOut className="sidebar-icon mr-2" />
        <span className="sidebar-text">Logout</span>
      </div>

      {error && <p className="text-red-500">{error}</p>}
    </motion.div>
  );
}

function MobileSideBar({ showSidebar, toggleSidebar }) {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // const  = useMediaQuery({ query: '(orientation: portrait)' })
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLogout = async () => {
    try {
      // Retrieve refresh token from local storage
      const refreshToken = localStorage.getItem("refreshToken");
      console.log("Refresh Token:", refreshToken);

      // Check if refresh token is available
      if (!refreshToken) {
        console.error("Refresh token not found.");
        setError("Logout failed. Refresh token not found.");
        return;
      }

      // Make a request to the refresh endpoint
      const refreshResponse = await axios.post(
        "http://164.92.83.167:8080/v1/user/token/refresh/",
        {
          refresh: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Refresh Response Status Code:", refreshResponse.status);
      console.log("Refresh Response Data:", refreshResponse.data);

      // Assuming a successful refresh, clear local storage and navigate to login page
      if (refreshResponse.status === 200) {
        localStorage.removeItem("authAccessToken");
        localStorage.removeItem("refreshToken");

        const newAccessToken = refreshResponse.data.access;

        // Save the new access token in local storage
        localStorage.setItem("authAccessToken", newAccessToken);

        navigate("/login");
      } else {
        console.error("Refresh failed:", refreshResponse.data.message);
        setError("Logout failed. Please try again.");
      }
    } catch (error) {
      console.error("Refresh failed:", error);
      setError("Logout failed. Please try again.");
    }
  };

  const animation = {
    initial: {
      display: "none",
      transform: "translateX(-100vw)",
    },
    slideOut: {
      display: "flex",
      transform: "translateX(0vw)",
      transition: {
        duration: 0.5,
      },
    },
    slideIn: {
      transform: "translateX(-100vw)",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      className={`sidebar overflow-y-auto ${
        showSidebar ? "sidebar-open" : "sidebar-close"
      }`}
      variants={animation}
      initial="initial"
      animate={showSidebar ? "slideOut" : "slideIn"}
      id="mobile"
    >
      <div className="mobile-hamburger sticky" onClick={toggleSidebar}>
        <FiMenu className="hamburger-icon" />
      </div>

      <div className="w-full gap-9 flex flex-col">
        <div className="logor flex-shrink-0 flex items-center gap-2 pb-8 md:pb-0">
          <img className="w-8" src={logoImg} alt="Request Mechanic" />
          <img className="w-16" src={logoTxt} alt="" />
        </div>

        <div className="mt-8 w-full min-h-[50vh] gap-[5vh] flex flex-col">
          <Link
            to="/dashboard"
            className="no-underline flex items-center py-1 mb-3 text-white gap-4 md:gap-0 text-xl md:text-sm"
          >
            <FiHome className="sidebar-icon mr-2" />
            <span className="sidebar-text ">Dashboard</span>
          </Link>

          <Link
            to="/transactions"
            className="no-underline flex items-center py-1 mb-4 text-white gap-4 md:gap-0 text-xl md:text-sm"
          >
            <FiDollarSign className="sidebar-icon mr-2" />
            <span className="sidebar-text">Transactions</span>
          </Link>
          <Link
            to="/call-logs"
            className="no-underline flex items-center py-1 mb-4 text-white gap-4 md:gap-0 text-xl md:text-sm"
          >
            <FiPhoneCall className="sidebar-icon mr-2" />
            <span className="sidebar-text">Call Logs</span>
          </Link>
          <UsersChevron />
        </div>
      </div>
      <div
        className="flex items-center py-1 my-4 text-white gap-4 md:gap-0 text-xl md:text-sm cursor-pointer"
        onClick={handleLogout}
      >
        <FiLogOut className="sidebar-icon mr-2" />
        <span className="sidebar-text">Logout</span>
      </div>

      {error && <p className="text-red-500">{error}</p>}
    </motion.div>
  );
}

function UsersChevron() {
  const [showUserLinks, setShowUserLinks] = useState(false);
  const toggleUserLinks = () => {
    setShowUserLinks(!showUserLinks);
  };

  return (
    <>
      <Link
        to="#"
        className="no-underline flex items-center py-1 gap-4 mb-2 text-white text-xl md:text-sm"
        onClick={toggleUserLinks}
      >
        <FiUsers className="sidebar-icon mr-2" />
        <span className="sidebar-text">Users</span>
        <FiChevronRight
          className={showUserLinks ? "ml-auto rotate-90" : "ml-auto"}
        />
      </Link>
      {showUserLinks && <ChevronContent />}
    </>
  );
}

function ChevronContent() {
  return (
    <div className="flex flex-col w-full gap-3">
      <Link
        to="/car-owners"
        className="sublink-text no-underline text-white w-full bg-gray-900 hover:bg-gray-800 rounded-md p-2 text-xl md:text-sm"
      >
        Car Owners
      </Link>
      <Link
        to="/mechanics"
        className="sublink-text no-underline text-white w-full bg-gray-900 hover:bg-gray-800 rounded-md p-2 text-xl md:text-sm"
      >
        Mechanics
      </Link>
    </div>
  );
}

export default Sidebar;

