export const CustomerPP = () => {
    return (
      <>
        <p className="mb-3">
        Requestmechanic.com is provided by RequestMechanic Limited (“RequestMechanic”). At RequestMechanic, 
        we value your privacy and we are committed to safeguarding your personal information. All personal
        information that you provide us will be protected and kept confidential among our affiliates, representatives,
        and privies. Throughout the website, the terms “we”, “us” and “our” refer to RequestMechanic.
        This Privacy Policy explains how we collect, use, share and protect your personal information in connection
        with your relationship with us as a user or potential user. It applies to all our clients, potential clients, 
        consultants, partners, technicians and every other person we hold information about. This policy also sets out 
        your rights and who you may contact for further information. You agree to this Privacy Policy by visiting our 
        website and when you use our services. Your use of our services, and any dispute over privacy is subject to this 
        Policy and our Terms of Service, including its applicable limitations on damages and the resolution of disputes. 
        Our Terms of Service are incorporated by reference into this Policy. Our website and services are not directed at you 
        if we are prohibited by any law of any jurisdiction from making the information on our website available to you 
        and is not intended for any use that would be contrary to local law or regulation.
        </p>
  
        <h3 className="text-sm font-semibold">Age Restriction</h3>
        <p className="mb-3">
          You affirm that you are over the age of majority and have the right to
          contract in your own name, and that you have read the above
          authorisation and fully understand its contents.
        </p>
        <h3 className="text-sm font-semibold">Information We Collect.</h3>
        <p className="mb-3">
        There are two categories of information we collect. We collect a variety of information from our users and visitors to our website. As described below, some information is automatically collected when you visit our website, and some you provide to us when filling out a form or communicating with us.
          <ul className="list-disc ml-5">
            <li>
              <b>Information Collected Automatically:</b>Whenever you visit our website, our web servers automatically collect non-personal information such as the domain name of the internet access provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, and other relevant statistics.
            </li>
            <li>
              <b>Information You Provide Us:</b> If you provide us with personal information, by contacting us, or subscribing to our services we collect the following personal information:
              <br />
              <li className="ml-5">Username and password</li>
              <li className="ml-5">
                Contact information including phone numbers (for contacting in
                case of contracts or delivery of automotive services being
                offered)
              </li>
              <li className="ml-5">Payment information</li>
              <li className="ml-5">
                Vehicle information (including vehicle owner information, vehicle
                identification numbers and vehicle specifications)
              </li>
              <li className="ml-5">
                Real-time location (for automotive specialist matching and service
                delivery purposes)
              </li>
              <li className="ml-5">Any other information you provide to us.</li>
            </li>
          </ul>
        </p>
  
        <h3 className="text-sm font-semibold">
          Using Your Personally Identifiable Information
        </h3>
        <p className="mb-3">
        “Personally Identifiable Information” means any information that (a) identifies or can be used to identify, contact, or locate the person to whom such information pertains, or (b) from which identification or contact information of an individual person can be derived. We primarily collect your information to ensure that we provide the most efficient service to you, monitor the use and improve our website and other legitimate interests. Your information will solely be used and disclosed for the following purposes:
          <ul className="list-disc ml-5">
            <li>To help us verify your identity</li>
            <li>
            To carry out our obligations ensuing from any contracts entered into between you and us
            </li>
            <li>
            To provide you with the products, services and information you request from us;
            </li>
            <li>To assist you with enquiries and improve our customer service</li>
            <li>
            To assist us in carrying out marketing analysis and customer profiling (including transactional information), conduct research, including creating statistical and testing information
            </li>
            <li>
            To allow us to communicate with you in any way (including e-mail, telephone, visit, and text or multimedia messages)
            </li>
            <li>For our billing and account purposes</li>
            <li>To help prevent and detect fraud or loss</li>
            <li>To update our records</li>
            <li>
              To make recommendations and suggestions to you about services
              offered by us unless you have previously asked us not to do so
            </li>
            <li>
              To send you service or support messages, such as updates, security
              alerts, email notifications and /or newsletters
            </li>
            <li>To conduct investigations and risk assessments; and</li>
            <li>For compliance with legal and regulatory obligations</li>
          </ul>
        </p>
  
        <h3 className="text-sm font-semibold">Other Information We Collect</h3>
        <p className="mb-3">
        Other information which may be automatically collected from you when you visit our website include; domain name of your internet service provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, your geographical location, operating system, referral source, and other relevant statistics. We may also collect information from you using cookies and other analytical tools especially when you use our products and services. More details are provided below in our section on Cookies.
        </p>
  
        <h3 className="text-sm font-semibold">Disclosures</h3>
        <p className="mb-3">
        To provide you with our services, we will share information about your vehicle(s) and the location of your vehicle(s) with third party technicians that we work with.
        </p>
        <p className="mb-3">
        We will not sell, publish, or disclose to third parties your personally identifiable information collected on our website, through our servers or otherwise obtained by us, other than to provide our services and as set forth in this Privacy Policy. We may share generic aggregated demographic information not linked to any personally identifiable information regarding visitors and Users with our business partners, trusted affiliates, professional advisers and advertisers for the purposes outlined above. We may share your information with these third parties for those limited purposes if you have given us your permission and in compliance with the Nigeria Data Protection Regulation 2019 (NDPR).
        </p>
        <p className="mb-3">
        We may request and provide information about you from and to third parties to provide our services.
        </p>
        <p className="mb-3">
        We will notify you as soon as we become aware of a harmful data breach which may result in a risk of your rights and freedom.
        </p>
        <p className="mb-3">
        You have the right to request an erasure of your data at any time.
        </p>
        <p className="mb-3">We will notify you if we are transferring your data
        </p>
        <p className="mb-3">You may request at any time that we halt further dissemination of your data or cease to use your data.
        </p>
        <p className="mb-3">If you submit content in a public forum or a social media post, or use a similar feature on our website, that content is publicly visible.
        </p>
        <p className="mb-3">We may disclose Personally Identifiable Information if required to do so by law or in the good faith belief that such action is necessary to (a) conform with the requirements of the law or comply with legal process served on us, or (b) act in urgent circumstances to protect the personal safety of users of our service or members of the public.
        </p>
        <p className="mb-3">To the extent practicable and legally permitted, we will attempt to advise you prior to any such disclosure, so that you may seek a protective order or other relief limiting such disclosure.</p>
  
        <h3 className="text-sm font-semibold">International Transfers</h3>
        <p className="mb-3">The transfer of your information to a foreign country or international organisation will only be done with your consent and in compliance with the NDPR. We will ensure that there are adequate data protection laws in the recipient country or organisation before transferring your information.
        </p>
  
        <h3 className="text-sm font-semibold">Your Rights</h3>
        <p className="mb-3">
        Subject to certain limitations and exceptions, you are entitled to the following principal rights under the NDPR:
          <ul className="list-disc ml-5">
            <li>
              We may request and provide information about you from and to third parties to provide our services.
            </li>
          </ul>
        </p>
        <h3 className="text-sm font-semibold" >Personal Data Deletion </h3>
        <p className="mb-3">
            <ul className="list-disc ml-5">
            <li>
              You have the right to request an erasure of your personal
              information at any time. Contact {" "}
              <a href="mailto:support@Requestmechanic.com">
                support@requestmechanic.com
              </a>
              {" "}
               to delete your account.
            </li>
            <li>
              You have the right to request that we rectify inaccurate personal
              information.
            </li>
            <li>
              You may request at any time that we halt further dissemination of
              your data or cease to use your personal information.
            </li>
            <li>
              You have the right to request for copies of your personal
              information.
            </li>
          </ul>
          </p>
        <h3 className="text-sm font-semibold">Website Security</h3>
        <p className="mb-3">
        We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures such as secure sockets layer (SSL) to safeguard and secure the information we collect online. We use encryption tools when accepting and transmitting delicate visitor information through our website.
        </p>
  
        <h3 className="text-sm font-semibold">Use of Cookies</h3>
        <p className="mb-3">
        We use cookies to identify you as a user and make your user experience easier, customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember your account log-in information when you visit our website, IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities. If your browser or browser add-on permits, you have the choice to disable cookies on our website; however, this may limit your ability to use our website. 
        </p>
  
        <h3 className="text-sm font-semibold">The Data We Retain</h3>
        <p className="mb-3">
        We will retain your information for as long as needed to provide you with our services, comply with our legal and statutory obligations or verify your information with a financial institution. We are statutory obligated to retain the data you provide us with in order to process transactions, ensure settlements, make refunds, identify fraud and in compliance with laws and regulatory guidelines applicable to us, our banking providers and credit card processors.
        </p>
  
        <h3 className="text-sm font-semibold">Links to Third Party Websites</h3>
        <p className="mb-3">
          <ul className="list-disc ml-5">
            <li>
              Our website may contain links to third-party websites or services
              that are not owned or controlled by us.
            </li>
            <li>
            We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.
            </li>
            <li>
              We strongly advise you to read the terms and conditions and privacy
              policies of any third-party websites or services that you visit.
            </li>
          </ul>
        </p>
  
        <h3 className="text-sm font-semibold">Limitation to Liability</h3>
        <p className="mb-3">We exercise reasonable efforts to safeguard the security and confidentiality of your personal data; however, we will not be liable for unauthorised disclosure of personal data that occurs through no fault of ours.
        </p>
  
        <h3 className="text-sm font-semibold">Changes to this Privacy Policy</h3>
        <p className="mb-3">
          Changes may be made to this Privacy Policy from time. Whenever such
          changes are made, we will notify you. These changes will take effect
          immediately after you have been notified.
        </p>
  
        <p className="mb-3">
          If you would like more information or you have any comments or questions
          on our privacy policy, please contact us at{" "}
          <a href="mailto:support@Requestmechanic.com">
            support@Requestmechanic.com
          </a>
        </p>
  
        <p>This policy is effective as of 3/03/2024</p>
      </>
    );
  };