import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { numberWithCommas } from "../utils";
import { format } from "date-fns";

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export const Transactions = () => {
  const { email } = useParams();
  const [userTransactions, setUserTransactions] = useState([]);

  console.log(email);

  const fetchUserTransactions = async () => {
    const accessToken = localStorage.getItem("authAccessToken");

    try {
      const response = await axios.get(
        `${baseUrl}/client/user-transactions/?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Transaction History Response:", response.data.data);
      setUserTransactions(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserTransactions();
  }, [email]);

  return (
    <TransactionsView>
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4 text-left">Reference</th>
              <th className="p-4 text-left">Transaction Type</th>
              <th className="p-4 text-left">Amount</th>
              <th className="p-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {userTransactions.map((transaction) => (
              <tr key={transaction?.id} className="bg-white">
                <td className="p-4">{transaction?.reference}</td>
                <td className="p-4">{transaction?.transaction_type}</td>
                <td className="p-4">
                  ₦{numberWithCommas(transaction?.amount || "00.00")}
                </td>
                <td className="p-4">
                  {format(new Date(transaction?.date_created), "do MMM, yyyy")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TransactionsView>
  );
};

const TransactionsView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 2rem;
`;