import React from 'react'
import styled from 'styled-components'

export const Title = ({ text }) => {
  return (
    <StyledTitle>
      <h2 className='title'>{text}</h2>
    </StyledTitle>
  )
}

const StyledTitle = styled.div`
  margin: 20px 0;

  .title {
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
`;