

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logoImg from "../Images/RM - Idan-03 1.svg";
import logoTxt from "../Images/Request Mechanic.png";
import NavListHeader from "./DynamicHeader";
import MobileMenu from "./mobileMenu";
import footerLogo from "../Images/Frame 1000000874.png";
import ErrorImage from "../Images/404.png"
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiArrowRight,
  FiArrowUpRight,
} from "react-icons/fi";
import "../App.css"
import mixpanel from 'mixpanel-browser'




export default function ErrorPage(){
  useEffect(()=>{
    mixpanel.track("error page")
  },[])
    return <div  className=" min-h-screen h-fit ErrorContainer  ">
                <Header/>
                <Content/>
                <Footer/>
            </div>
}




function RequestNow(){
  return   <a href="https://service.requestmechanic.com/form" className="request-nav-button flex items-center gap-2 text-black text-sm py-2 px-3 w-fit flex-grow-0" >
            Request Now
          <FiArrowRight className="FiArrowRight" style={{ color: "black", height: "20px", width: "20px" }}/>
          </a>
}

function Logo(){
  return <div className="flex gap-1">
              <img
                className="object-contain h-8"
                src={logoImg}
                alt="Request Mechanic"
              />
              <img className="object-contain h-8" src={logoTxt} alt="" />
          </div>
}
function LogoAndNavList(){
  return <div className="flex gap-10">
              <Logo/>
              <NavListHeader />
            </div>
}
function Header(){
  return  <nav className="fixed z-50 top-0 left-0 w-full bg-black px-[25px] sm:px-[50px] md:px-[100px] py-[30px] mb-[150px] flex flex-row items-center justify-between border-b border-b-gray-900">
              <LogoAndNavList/>
              <RequestNow/>
              <MobileMenu />
            </nav>
}

function Footer(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const navigate  = useNavigate();

  const openModal = (mType) => {
    setIsModalOpen(true);
    setModalType(mType);

    if (mType === "pp") {
      navigate("#privacy-policy");
    } else if (mType === "tnc") {
      navigate("#terms-and-condition");
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalType("");
    navigate(window.location.pathname);
  };
  return <footer className="bg-black text-white py-8 mt-32 px-4">
            <div className="grid gap-10 xs:grid-cols-1 sm:grid-cols-3 lg:grid-cols-4">
              {/* Footer Header */}
              <div className="sm:flex sm:flex-col sm:items-start">
                <img
                  src={footerLogo}
                  alt="Footer Logo"
                  className="mb-4 cursor-pointer"
                />
                {/* <p className='footer-text'>RequestMechanic is a platform that <br/> provides you one-click instant access to a network of certified <br/> mechanics and auto-experts</p> */}
                <p className="footer-text">
                  RequestMechanic is a mobile application that provides you
                  one-click instant access to certified mechanics and auto-experts
                </p>
              </div>

              {/* Company */}
              <div className="sm:text-center flex flex-col sm:items-center">
                <p className="font-bold cursor-pointer">Company</p>
                <a
                  href="#features-section"
                  className="text-white underline-offset-1 text-xs mt-3 mb-2"
                >
                  Features
                </a>
                <a
                  href="#support-section"
                  className="text-white underline-offset-1 text-xs my-2"
                >
                  Support
                </a>

                <button
                  onClick={(e) => openModal("tnc")}
                  className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
                >
                  Terms & Conditions
                </button>

                <button
                  onClick={(e) => openModal("pp")}
                  className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
                >
                  Privacy Policy
                </button>
                <TermsModal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  modalType={modalType}
                />
              </div>
              <div className="text-center flex flex-col items-center">
                <p className="font-bold cursor-pointer">Contact</p>
                <a
                  id="support-section"
                  href="mailto:Support@requestmechanic.com"
                  className="email-link my-3"
                >
                  <p className="email-link">Support@requestmechanic.com</p>
                </a>
                <div className="flex gap-8">
                  <a href="https://www.facebook.com/profile.php?id=100095295696587&mibextid=LQQJ4d">
                    <FiFacebook className="text-white text-xl cursor-pointer" />
                  </a>
                  <a href="https://twitter.com/requestmechanic?s=11">
                    <FiTwitter className="text-white text-xl cursor-pointer" />
                  </a>
                  <a href="https://instagram.com/requestmechanic?igshid=MzRlODBiNWFlZA==">
                    <FiInstagram className="text-white text-xl cursor-pointer" />
                  </a>
                </div>
              </div>
            </div>
  </footer>
}

export const TermsModal = ({ isOpen, onClose, modalType }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 p-4 md:p-14 z-50 flex justify-center items-center">
      <div className="bg-white text-black p-6 md:p-16 max-w-full md:max-w-5xl max-h-fullmx-auto rounded shadow-lg">
        <h2 className="text-xl font-semibold mb-4">
          {modalType === "tnc" ? "Terms & Conditions" : "Policy Privacy"}
        </h2>
        <div className="text-xs w-full text-left">
          {modalType === "tnc" ? <TnC /> : <PP />}
        </div>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition ease-in-out duration-150"
        >
          I Agree
        </button>
      </div>
    </div>
  );
};

export const TnC = () => {
  return (
    <>
      <h3 className="text-sm font-semibold">General Terms</h3>
      <p className="mb-3">
        By accessing and placing an order with REQUESTMECHANIC.COM, you confirm
        that you are in agreement with and bound by the terms of service
        contained in the Terms & Conditions outlined below. These terms apply to
        the entire website and any email or other type of communication between
        you and REQUESTMECHANIC.COM.
      </p>

      <h3 className="text-sm font-semibold">Quality Assurance</h3>
      <p>
        For calls: REQUESTMECHANIC ensures the quality of calls, connections,
        and services by implementing guidelines and measures to monitor the
        conduct of its auto experts. To ensure call quality, we record every
        call made on the platform, providing necessary information for dispute
        resolution. Additionally, we offer a 5-star rating system for users to
        rate their connections, allowing us to address low-quality experts.
        REQUESTMECHANIC also provides a replacement call if an auto expert
        behaves unprofessionally.
      </p>
      <p className="mb-3">
        By accessing and placing an order with REQUESTMECHANIC.COM, you confirm
        that you agree to be bound by the terms of service contained in the
        Terms & Conditions outlined below. These terms apply to the entire
        website and any communication between you and REQUESTMECHANIC.COM.
      </p>
      <p className="mb-3">
        Under no circumstances shall REQUESTMECHANIC.COM or its team be liable
        for any direct, indirect, special, incidental, or consequential damages,
        including loss of data or profit, arising from the use or inability to
        use the materials on this site, even if notified of the possibility of
        such damages. If your use of materials from this site requires
        servicing, repair, or correction of equipment or data, you assume any
        associated costs.
      </p>
      <p className="mb-3">
        REQUESTMECHANIC.COM will not be responsible for any outcomes that may
        occur during the usage of our resources. We reserve the right to change
        prices and revise the resources usage policy at any time.
      </p>

      <h3 className="text-sm font-semibold">License</h3>
      <p className="mb-3">
        REQUESTMECHANIC.COM grants you a revocable, non-exclusive,
        non-transferable, limited license to download, install, and use the
        website/app strictly in accordance with the terms of this Agreement. By
        using the REQUESTMECHANIC Service, you agree to be bound by these Terms
        & Conditions.
      </p>

      <h3 className="text-sm font-semibold">Restrictions</h3>
      <p className="mb-3">
        You agree not to license, sell, rent, lease, assign, distribute,
        transmit, host, outsource, disclose, or otherwise commercially exploit
        the website/app or make it available to any third party. You also agree
        not to modify, make derivative works of, disassemble, decrypt, reverse
        compile, or reverse engineer any part of the website/app.
      </p>

      <h3 className="text-sm font-semibold">Your Suggestions</h3>
      <p className="mb-3">
        Any feedback, comments, ideas, improvements, or suggestions provided by
        you to REQUESTMECHANIC regarding the website/app shall remain the sole
        and exclusive property of REQUESTMECHANIC. REQUESTMECHANIC may use,
        copy, modify, publish, or redistribute the suggestions without providing
        credit or compensation to you.
      </p>

      <h3 className="text-sm font-semibold">Your Consent</h3>
      <p className="mb-3">
        By using our website/app, registering an account, or making a purchase,
        you consent to our Terms & Conditions.
      </p>

      <h3 className="text-sm font-semibold">Membership and Registration</h3>
      <p className="mb-3">
        Our services are available only to individuals who are 18 years and
        older and who can form legally binding contracts under applicable law.
        By registering for an account and using our services, you confirm that
        you are at least 18 years old and that all registration information you
        submit is accurate and truthful.
      </p>
      <p className="mb-3">
        Users who meet the eligibility criteria stated above must create an
        account by providing the following information:
        <ul className="list-disc ml-5">
          <li className="ml-5">Full name</li>
          <li className="ml-5">Email address</li>
          <li className="ml-5">
            Telephone number (for contacting in case of contracts or delivery of
            automotive services being offered)
          </li>
          <li className="ml-5">
            Car information (for matching with the correct automotive
            specialists)
          </li>
          <li className="ml-5">Physical address</li>
          <li className="ml-5">Date of Birth (for age restriction purposes)</li>
        </ul>
      </p>
      <p className="mb-3">
        At this point, you are required to confirm your agreement with our terms
        of use and privacy policy by ticking a box. A one-time password will be
        provided to you upon signing up.
      </p>
      <p className="mb-3">
        This information is collected to facilitate your access to our
        automotive consultation services and ensure that our platform operates
        effectively. It helps us match you with the appropriate automotive
        specialists, verify your identity, and provide accurate and personalized
        services.
      </p>

      <h3 className="text-sm font-semibold">Personal Data</h3>
      <p className="mb-3">
        The company collects and processes users’ personal data. For further
        details, every user is encouraged to read the Platform’s “Privacy
        Policy” . For the avoidance of doubt, with the exception of our payment
        provider, we do not share your details with any other third parties.
      </p>
      <h3 className="text-sm font-semibold">Links to Other Websites</h3>
      <p className="mb-3">
        This Terms & Conditions applies only to the Services. The Services may
        contain links to other websites not operated or controlled by
        REQUESTMECHANIC. We are not responsible for the content, accuracy, or
        opinions expressed on such websites. Your browsing and interaction on
        any other website are subject to that website's own rules and policies.
      </p>

      <h3 className="text-sm font-semibold">Cookies</h3>
      <p className="mb-3">
        REQUESTMECHANIC uses cookies to identify the areas of our website that
        you have visited. These cookies enhance the performance and
        functionality of our website/app but are non-essential to their use.
        However, disabling cookies may limit access to certain functionality. We
        never place Personally Identifiable Information in Cookies.
      </p>

      <h3 className="text-sm font-semibold">
        Changes to Our Terms & Conditions
      </h3>
      <p className="mb-3">
        If we decide to change our Terms & Conditions, we will post those
        changes on this page and/or update the modification date below.
      </p>

      <h3 className="text-sm font-semibold">
        Modifications to Our website/app
      </h3>
      <p className="mb-3">
        REQUESTMECHANIC reserves the right to modify, suspend, or discontinue
        the website/app or any service connected to it, with or without notice
        and without liability to you.
      </p>

      <h3 className="text-sm font-semibold">Updates to Our website/app</h3>
      <p className="mb-3">
        REQUESTMECHANIC may provide enhancements or improvements to the
        website/app's features/functionality, including patches, bug fixes,
        updates, upgrades, and other modifications. All updates will be
        considered integral parts of the website/app and subject to the terms of
        this Agreement.
      </p>

      <h3 className="text-sm font-semibold">Third-Party Services</h3>
      <p className="mb-3">
        We may display, include, or make available third-party content or
        provide links to third-party websites or services. REQUESTMECHANIC is
        not responsible for the accuracy, completeness, or quality of
        Third-Party Services, and accessing them is at your own risk.
      </p>

      <h3 className="text-sm font-semibold">Term and Termination</h3>
      <p className="mb-3">
        This Agreement remains in effect until terminated by you or
        REQUESTMECHANIC. REQUESTMECHANIC may suspend or terminate this Agreement
        at any time without prior notice. Termination of this Agreement does not
        limit REQUESTMECHANIC’s rights or remedies.
      </p>

      <h3 className="text-sm font-semibold">Copyright Infringement Notice</h3>
      <p className="mb-3">
        If you believe any material on our website/app infringes on your
        copyright, please contact us with specific information. REQUESTMECHANIC
        will act in accordance with applicable laws regarding copyright
        infringement.
      </p>

      <h3 className="text-sm font-semibold">Indemnification</h3>
      <p className="mb-3">
        You agree to indemnify and hold REQUESTMECHANIC harmless from any claim
        or demand arising from your use of the website/app or violation of this
        Agreement or any law.
      </p>

      <h3 className="text-sm font-semibold">No Warranties</h3>
      <p className="mb-3">
        The website/app is provided "AS IS" and "AS AVAILABLE" without
        warranties of any kind. REQUESTMECHANIC disclaims all warranties,
        including implied warranties of merchantability, fitness for a
        particular purpose, title, and non-infringement.
      </p>

      <h3 className="text-sm font-semibold">Limitation of Liability</h3>
      <p className="mb-3">
        REQUESTMECHANIC's liability for any damages arising from the use of the
        website/app is limited to the amount paid by you for the website/app.
        REQUESTMECHANIC is not liable for any special, indirect, or incidental
        damages.
      </p>

      <h3 className="text-sm font-semibold">Severability</h3>
      <p className="mb-3">
        If any provision of this Agreement is unenforceable, the remaining
        provisions will remain in full force and effect. This Agreement
        constitutes the entire agreement between you and REQUESTMECHANIC.
      </p>

      <h3 className="text-sm font-semibold">Waiver</h3>
      <p className="mb-3">
        Failure to exercise a right under this Agreement does not waive that
        right. No waiver of breach constitutes a waiver of subsequent breaches.
      </p>

      <h3 className="text-sm font-semibold">Amendments to this Agreement</h3>
      <p className="mb-3">
        REQUESTMECHANIC may modify this Agreement at any time. By continuing to
        use the website/app after revisions, you agree to the updated terms.
      </p>

      <h3 className="text-sm font-semibold">Entire Agreement</h3>
      <p className="mb-3">
        This Agreement supersedes all prior agreements between you and
        REQUESTMECHANIC regarding the website/app.
      </p>

      <h3 className="text-sm font-semibold">Updates to Our Terms</h3>
      <p className="mb-3">
        We may update our Terms to reflect changes in our Service and policies.
        By continuing to use the Service, you agree to the updated Terms.
      </p>

      <h3 className="text-sm font-semibold">Intellectual Property</h3>
      <p className="mb-3">
        The website/app and its contents are owned by REQUESTMECHANIC and
        protected by copyright and other intellectual property laws.
        Unauthorized use of the material is prohibited.
      </p>

      <h3 className="text-sm font-semibold">Agreement to Arbitrate</h3>
      <p className="mb-3">
        Disputes between you and REQUESTMECHANIC will be resolved through
        binding arbitration, except for disputes related to intellectual
        property rights.
      </p>

      <h3 className="text-sm font-semibold">Submissions and Privacy</h3>
      <p className="mb-3">
        Submissions to REQUESTMECHANIC become its property, and REQUESTMECHANIC
        may use them without compensation to you.
      </p>

      <h3 className="text-sm font-semibold">Promotions</h3>
      <p className="mb-3">
        Promotions offered by REQUESTMECHANIC may be governed by separate rules.
        You agree to comply with all Promotion rules.
      </p>

      <h3 className="text-sm font-semibold">Typographical Errors</h3>
      <p className="mb-3">
        REQUESTMECHANIC reserves the right to refuse or cancel orders due to
        incorrect pricing or information errors.
      </p>

      <h3 className="text-sm font-semibold">Miscellaneous</h3>
      <p className="mb-3">
        If any provision of these Terms is unenforceable, the remainder will
        remain in full force. These Terms govern your use of the website/app and
        supersede all prior agreements.
      </p>

      <h3 className="text-sm font-semibold">Disclaimer</h3>
      <p className="mb-3">
        REQUESTMECHANIC is not responsible for the accuracy or completeness of
        content on the website/app. We do not provide warranties or guarantees,
        and we may make changes to the content without prior notice.
        REQUESTMECHANIC disclaims all warranties and representations regarding
        the content, services, and products provided through or accessible via
        the website/app.
      </p>

      <h3 className="text-sm font-semibold">Contact Us</h3>
      <p className="mb-3">
        If you have any questions or concerns about these Terms & Conditions,
        please don't hesitate to contact us.
      </p>

      <p className="mb-3">
        By accessing or using the REQUESTMECHANIC Service, you agree to abide by
        these Terms & Conditions. If you do not agree with any part of these
        Terms, please refrain from using the Service.
      </p>

      <p>Thank you for using REQUESTMECHANIC.COM</p>
    </>
  );
};

export const PP = () => {
  return (
    <>
      <p className="mb-3">
        Requestmechanic.com is provided by RequestMechanic Limited
        ("RequestMechanic"). At RequestMechanic, we value your privacy and we
        are committed to safeguarding your personal information. All personal
        information that you provide us will be protected and kept confidential
        among our affiliates, representatives, and privies. Throughout the
        website, the terms "we", "us" and "our" refer to RequestMechanic. This
        Privacy Policy explains how we collect, use, share and protect your
        personal information in connection with your relationship with us as a
        user or potential user. It applies to all our clients, potential
        clients, consultants, partners, technicians and every other person we
        hold information about. This policy also sets out your rights and who
        you may contact for further information. You agree to this Privacy
        Policy by visiting our website and when you use our services. Your use
        of our services, and any dispute over privacy is subject to this Policy
        and our Terms of Service, including its applicable limitations on
        damages and the resolution of disputes. Our Terms of Service are
        incorporated by reference into this Policy. Our website and services are
        not directed at you if we are prohibited by any law of any jurisdiction
        from making the information on our website available to you and is not
        intended for any use that would be contrary to local law or regulation.
      </p>

      <h3 className="text-sm font-semibold">Age Restriction</h3>
      <p className="mb-3">
        You affirm that you are over the age of majority and have the right to
        contract in your own name, and that you have read the above
        authorisation and fully understand its contents.
      </p>
      <h3 className="text-sm font-semibold">Information We Collect.</h3>
      <p className="mb-3">
        There are two categories of information we collect. We collect a variety
        of information from our users and visitors to our website. As described
        below, some information is automatically collected when you visit our
        website, and some you provide to us when filling out a form or
        communicating with us.
        <ul className="list-disc ml-5">
          <li>
            <b>Information Collected Automatically:</b> Whenever you visit our
            website, our web servers automatically collect non-personal
            information such as the domain name of the internet access provider,
            the internet protocol address used to connect the computer to the
            internet, the average time spent on our website, pages viewed,
            information searched for, access times, and other relevant
            statistics.
          </li>
          <li>
            <b>Information You Provide Us:</b> If you provide us with personal
            information, by contacting us, or subscribing to our services we
            collect the following personal information:
            <br />
            <li className="ml-5">Username and password</li>
            <li className="ml-5">
              Contact information including phone numbers (for contacting in
              case of contracts or delivery of automotive services being
              offered)
            </li>
            <li className="ml-5">Payment </li>
            <li className="ml-5">
              Vehicle information (including vehicle owner information, vehicle
              identification numbers and vehicle specifications)
            </li>
            <li className="ml-5">
              Date of Birth (for age restriction purposes)
            </li>
            <li className="ml-5">
              Real-time location (for automotive specialist matching and service
              delivery purposes)
            </li>
            <li className="ml-5">Any other information you provide to us.</li>
          </li>
        </ul>
      </p>

      <h3 className="text-sm font-semibold">
        Using Your Personally Identifiable Information
      </h3>
      <p className="mb-3">
        "Personally Identifiable Information" means any information that (a)
        identifies or can be used to identify, contact, or locate the person to
        whom such information pertains, or (b) from which identification or
        contact information of an individual person can be derived. We primarily
        collect your information to ensure that we provide the most efficient
        service to you, monitor the use and improve our website and other
        legitimate interests. Your information will solely be used and disclosed
        for the following purposes:
        <ul className="list-disc ml-5">
          <li>To help us verify your identity</li>
          <li>
            To carry out our obligations ensuing from any contracts entered into
            between you and us
          </li>
          <li>
            To provide you with the products, services and information you
            request from us
          </li>
          <li>To assist you with enquiries and improve our customer service</li>
          <li>
            To assist us in carrying out marketing analysis and customer
            profiling (including transactional information), conduct research,
            including creating statistical and testing information
          </li>
          <li>
            To allow us to communicate with you in any way (including e-mail,
            telephone, visit, and text or multimedia messages)
          </li>
          <li>For our billing and account purposes</li>
          <li>To help prevent and detect fraud or loss</li>
          <li>To update our records</li>
          <li>
            To make recommendations and suggestions to you about services
            offered by us unless you have previously asked us not to do so
          </li>
          <li>
            To send you service or support messages, such as updates, security
            alerts, email notifications and /or newsletters
          </li>
          <li>To conduct investigations and risk assessments</li>
          <li>For compliance with legal and regulatory obligations</li>
        </ul>
      </p>

      <h3 className="text-sm font-semibold">Other Information We Collect</h3>
      <p className="mb-3">
        Other information which may be automatically collected from you when you
        visit our website include; domain name of your internet service
        provider, the internet protocol address used to connect the computer to
        the internet, the average time spent on our website, pages viewed,
        information searched for, access times, your geographical location,
        operating system, referral source, and other relevant statistics. We may
        also collect information from you using cookies and other analytical
        tools especially when you use our products and services. More details
        are provided below in our section on Cookies.
      </p>

      <h3 className="text-sm font-semibold">Disclosures</h3>
      <p className="mb-3">
        To provide you with our services, we will share information about your
        vehicle(s) and the location of your vehicle(s) with third party
        technicians that we work with.
      </p>
      <p className="mb-3">
        We will not sell, publish, or disclose to third parties your personally
        identifiable information collected on our website, through our servers
        or otherwise obtained by us, other than to provide our services and as
        set forth in this Privacy Policy. We may share generic aggregated
        demographic information not linked to any personally identifiable
        information regarding visitors and Users with our business partners,
        trusted affiliates, professional advisers and advertisers for the
        purposes outlined above. We may share your information with these third
        parties for those limited purposes if you have given us your permission
        and in compliance with the Nigeria Data Protection Regulation 2019
        (NDPR).
      </p>
      <p className="mb-3">
        We may request and provide information about you from and to third
        parties to provide our services.
      </p>
      <p className="mb-3">
        We will notify you as soon as we become aware of a harmful data breach
        which may result in a risk of your rights and freedom.
      </p>
      <p className="mb-3">
        You have the right to request an erasure of your data at any time.
      </p>
      <p className="mb-3">
        We will notify you if we are transferring your data.
      </p>
      <p className="mb-3">
        You may request at any time that we halt further dissemination of your
        data or cease to use your data.
      </p>
      <p className="mb-3">
        If you submit content in a public forum or a social media post, or use a
        similar feature on our website, that content is publicly visible.
      </p>
      <p className="mb-3">
        We may disclose Personally Identifiable Information if required to do so
        by law or in the good faith belief that such action is necessary to (a)
        conform with the requirements of the law or comply with legal process
        served on us, or (b) act in urgent circumstances to protect the personal
        safety of users of our service or members of the public.
      </p>
      <p className="mb-3">
        To the extent practicable and legally permitted, we will attempt to
        advise you prior to any such disclosure, so that you may seek a
        protective order or other relief limiting such disclosure.
      </p>

      <h3 className="text-sm font-semibold">International Transfers</h3>
      <p className="mb-3">
        The transfer of your information to a foreign country or international
        organisation will only be done with your consent and in compliance with
        the NDPR. We will ensure that there are adequate data protection laws in
        the recipient country or organisation before transferring your
        information.
      </p>

      <h3 className="text-sm font-semibold">Your Rights</h3>
      <p className="mb-3">
        Subject to certain limitations and exceptions, you are entitled to the
        following principal rights under the NDPR:
        <ul className="list-disc ml-5">
          <li>
            We may request and provide information about you from and to third
            parties to provide our services.
          </li>
          <li>
            You have the right to request an erasure of your personal
            information at any time.
          </li>
          <li>
            You have the right to request that we rectify inaccurate personal
            information.
          </li>
          <li>
            You may request at any time that we halt further dissemination of
            your data or cease to use your personal information.
          </li>
          <li>
            You have the right to request for copies of your personal
            information.
          </li>
        </ul>
      </p>

      <h3 className="text-sm font-semibold">Website Security</h3>
      <p className="mb-3">
        We are committed to ensuring that your information is secure. In order
        to prevent unauthorised access or disclosure, we have put in place
        suitable physical, electronic and managerial procedures such as secure
        sockets layer (SSL) to safeguard and secure the information we collect
        online. We use encryption tools when accepting and transmitting delicate
        visitor information through our website.
      </p>

      <h3 className="text-sm font-semibold">Use of Cookies</h3>
      <p className="mb-3">
        We use cookies to identify you as a user and make your user experience
        easier, customise our services, content and advertising; help you ensure
        that your account security is not compromised, mitigate risk and prevent
        fraud; and to promote trust and safety on our website. Cookies allow our
        servers to remember your account log-in information when you visit our
        website, IP addresses, date and time of visits, monitor web traffic and
        prevent fraudulent activities. If your browser or browser add-on
        permits, you have the choice to disable cookies on our website; however,
        this may limit your ability to use our website.
      </p>

      <h3 className="text-sm font-semibold">The Data We Retain</h3>
      <p className="mb-3">
        We will retain your information for as long as needed to provide you
        with our services, comply with our legal and statutory obligations or
        verify your information with a financial institution. We are statutory
        obligated to retain the data you provide us with in order to process
        transactions, ensure settlements, make refunds, identify fraud and in
        compliance with laws and regulatory guidelines applicable to us, our
        banking providers and credit card processors.
      </p>

      <h3 className="text-sm font-semibold">Links to Third Party Websites</h3>
      <p className="mb-3">
        <ul className="list-disc ml-5">
          <li>
            Our website may contain links to third-party websites or services
            that are not owned or controlled by us.
          </li>
          <li>
            We have no control over, and assume no responsibility for, the
            content, privacy policies, or practices of any third-party websites
            or services. You further acknowledge and agree that we shall not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with use of
            or reliance on any such content, goods or services available on or
            through any such websites or services.
          </li>
          <li>
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party websites or services that you visit.
          </li>
        </ul>
      </p>

      <h3 className="text-sm font-semibold">Limitation to Liability</h3>
      <p className="mb-3">
        We exercise reasonable efforts to safeguard the security and
        confidentiality of your personal data; however, we will not be liable
        for unauthorised disclosure of personal data that occurs through no
        fault of ours.
      </p>

      <h3 className="text-sm font-semibold">Changes to this Privacy Policy</h3>
      <p className="mb-3">
        Changes may be made to this Privacy Policy from time. Whenever such
        changes are made, we will notify you. These changes will take effect
        immediately after you have been notified.
      </p>

      <p className="mb-3">
        If you would like more information or you have any comments or questions
        on our privacy policy, please contact us at{" "}
        <a href="mailto:support@Requestmechanic.com">
          support@Requestmechanic.com
        </a>
      </p>

      <p>This policy is effective as of 3/03/2024</p>
    </>
  );
};

function Content(){
  return <motion.div className="w-full h-[90vh] flex flex-col justify-center items-center px-4 gap-4 ">
              <motion.div className="w-full flex items-center justify-center" id="ErrorImgContainer" >
                  <motion.img src={ErrorImage} alt="404 page" className="w-full landscape:w-auto landscape:h-[40vh] "/>
              </motion.div>
              <BackButton  />
          </motion.div>
}


function BackButton(){
  return <a href="/" className="request-nav-button flex items-center gap-2 text-black text-sm py-2 px-3 w-fit flex-grow-0" id="backButtonA" >
              Go Back Home
            </a>
}