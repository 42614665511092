import React, { useEffect, useMemo, useRef, useState } from "react";
import { Call,Message,More } from "iconsax-react";
import {MeetingProvider, useMeeting, useParticipant,useMediaDevice} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import Controll_bg from "../../Images/call_control_bg.svg";
import SingleChat from "../chat/components/SingleChat";
// import ChatSessesion from "./chat";
import axios from "axios";


const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
  
export default function Calls({id,customersEmail,customersName,carModel,profile_pic}){
  const [meetingId, setMeetingId] = useState(null);
  const [shouldOpenChat,setShouldOpenChat] = useState(false);
  const [chatId, setChatId] = useState('');
  const [authToken, setAuthToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNjY3OTg2NywiZXhwIjoxNzU4MjE1ODY3fQ.o9w3qWNc3Y6caNnq5u6WAsc4Vx1l8sC6Pow9hhZtRnQ")
  // const { requestPermission } = useMediaDevice();

  function openChat(){
    if (chatId !="") {
        setShouldOpenChat(init=>true);
    }
  }
  function closeChat(){
    setShouldOpenChat(init=>false);
  }
  function onMeetingLeave(){
    setMeetingId(null);
  };

  function GenerateMeetingToken(){
    const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
                axios.get(`${baseUrl}/calls/generate-videosdk-token/`,
                {
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    console.log("token response",response);
                    // setToken(init=> response.data.data?.token)
                    return response
                }).catch(function(error){
                    console.log(error);
                })

        }
  }


useEffect(function(){
    // GenerateMeetingToken()
    const userEmail = localStorage.getItem("userEmail");
    setMeetingId(init=>id);
    setChatId(init=> `${customersEmail}_${userEmail}`);
    // requestPermission("audio_video");
},[]);

return <div className="w-screen h-screen relative" >
          {(meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: true, name: customersName,}} token={authToken} reinitialiseMeetingOnConfigChange={true} joinWithoutUserInteraction={true}>
          <Content openChat={openChat} onMeetingLeave={onMeetingLeave}/>
          </MeetingProvider>)}
          {shouldOpenChat && <div className="absolute top-0 left-0 z-[10]  ">
                      <SingleChat handleBack={closeChat} carModel={carModel} mechanicName={customersName} profile_pic={profile_pic} mechanicEmail={customersEmail}  />
          </div>}
</div>
} 

// ChatSessesion  reinitialiseMeetingOnConfigChange={true} joinWithoutUserInteraction={true}>

function Content({onMeetingLeave,openChat}){
  const {join, participants} = useMeeting();
  useEffect(function(){
    console.log("keys",[...participants.keys()]);
   },[])
  const participantOne = useParticipant([...participants.keys()][0])
  const participantTwo = useParticipant([...participants.keys()][1])

  useEffect(function(){
    join()
  },[])

  return <div className="w-full h-full">
                    <RemoteScreen participantId={participantOne.isLocal ? [...participants.keys()][1] : [...participants.keys()][0]}/>
                    <div className=" w-full h-full absolute top-0 left-0 flex flex-col justify-between">
                        <LocalScreen participantId={participantOne.isLocal ? [...participants.keys()][0] : [...participants.keys()][1]}/>
                        <Controlz openChat={openChat} displayName={participantOne.isLocal?participantTwo.displayName:participantOne.displayName}/>
                    </div>
  </div>
}

function Countdown({stopCount}){
  const [seconds,setSeconds] = useState(0);
  const [minute,setMinute] = useState(0);
  const [hour,setHour] = useState(0);
const asecond = 1000;
const aMinute = asecond * 60;
const anHour = aMinute *60;
let secondsInterval = useRef('')
let minuteInterval = useRef('')
let hourInterval = useRef('')
function doubleFormat(value){

    if (String(value).length < 2) {
      return `0${value}`
    }else return value
  }

  useEffect(function(){
    secondsInterval.current = setInterval(function(){
        setSeconds(init=>{return init >= 59 ? 0:init +1})
    },1000)

    return function(){
      clearInterval(secondsInterval.current);
      
    }
  },[])

  useEffect(function(){
     minuteInterval.current = setInterval(function(){
        setMinute(init=>{return init >= 59 ? 0:init +1}) 
          return function(){
            clearInterval(minuteInterval.current);
            
          }
    },60000);
  },[])

  useEffect(function(){
     hourInterval.current = setInterval(function(){
      setHour(init=>init++)
    },3600000)

    return function(){
      clearInterval(hourInterval.current);
      
    }
  },[])

  useEffect(function(){
    if (stopCount) {
      clearInterval(secondsInterval.current)
      clearInterval(minuteInterval.current)
      clearInterval(hourInterval.current)
    }
  },[stopCount])

  return <div className="w-fit h-fit p-2 bg-slate-300 bg-opacity-60  rounded-lg">
    <p className="text-white">{doubleFormat(hour)}:{doubleFormat(minute)}:{doubleFormat(seconds)}</p>
  </div>
}

function RemoteScreen({participantId}){
  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);
  // const {disableCam} = useMeeting();
  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);


  // useEffect(function(){
  //   disableCam();
  // },[])

 return <div id="remoteScreen" className="w-full h-full bg-black">
              <audio ref={micRef} autoPlay playsInline muted={isLocal} />
              {webcamOn && <ReactPlayer
                    className="reactVideoPlayer"
                    playsinline 
                    pip={false}
                    light={false}
                    controls={false}
                    muted={false}
                    playing={true}
                    url={videoStream}
                    style={{minHeight:"100%",borderRadius:"0.8rem"}}
                    height={"100%"}
                    width={"auto"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />}
         </div>
}

function LocalScreen({participantId}){
  // console.log(participantId);

  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      console.log("web cami s on");
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      console.log("mic ref is not null");
      if (micOn && micStream) {
      console.log("mic  is on and will stream");
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);
  

  return  <div id="localScreen" className="relative top-[5vh] right-[-57vw] w-[35vw] h-[25vh] rounded-lg ">
              <audio ref={micRef} autoPlay playsInline muted={isLocal} />
              <ReactPlayer
                    //
                    className="reactVideoPlayer"
                    playsinline // extremely crucial prop
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    //
                    url={videoStream}
                    //
                    style={{minHeight:"25vh",borderRadius:"0.8rem", transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)"}}
                    height={"25vh"}
                    width={"auto"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />
          </div>
}

function Controlz({displayName,openChat}){
  const [showCountdown,setShowCountdown] = useState(false)
  const [stopCount,setstopCount] = useState(false)
  const { end, toggleMic, toggleWebcam,} = useMeeting({
    onParticipantJoined,
    onMeetingLeft
  });

  function onParticipantJoined(){
    setShowCountdown(init=>true)
  }

  function onMeetingLeft(){
    setstopCount(init=>true)
  }

  return <div id="nameAndControls" className="flex flex-col">
              <div className="relative z-[1] px-4 py-1">
                <p className="text-white text-3xl mb-2">{displayName}</p>
                {showCountdown && <Countdown stopCount={stopCount}/>}
              </div>
              <div className="relative flex flex-col text-white items-center">
                <img src={Controll_bg} className="absolute z-[0] bottom-0 left-0 w-full"/>
                <div onClick = {()=>{end(); window.history.back()}} className=" relative top-[10%] w-fit h-fit p-8 rounded-full bg-red-700">
                      <Call className="z-[1]"/>
                </div>
                <div className="z-[1] relative w-full flex justify-between px-4 py-2 items-baseline">
                  <div onClick={openChat} className="w-fit h-fit bg-white rounded-2xl p-3">
                    <Message variant="Bold" className="text-green-600"/>
                  </div>
                  <p className="text-sm text-slate-200">your call  is secure</p>
                  <div className="w-fit h-fit  bg-slate-200 bg-opacity-30 rounded-2xl p-3">
                    <More className="rotate-90"/>
                  </div>
                </div>
              </div>
</div>
}


