import { useDispatch, useSelector } from "react-redux";
import { LoaderKey } from "../../Components/loaderKey";
import { useEffect, useState } from "react";
import { TextError } from "../../Components/utils";
import ProgressBar from "@ramonak/react-progress-bar"; //the green progress bar
import * as Yup from "yup";
import Button from "../../Components/button/index";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormikControl from "../../Components/formik/formikControl";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { userCreateAccountStep2 } from "../../Components/store/actions/userAction";
import "react-datepicker/dist/react-datepicker.css";

const MechanicCreateAccount2 = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const initialValues = {
    first_name: "",
    last_name: "",
    // phone_number:'',
    // date_of_birth:''
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    // phone_number: Yup.string().required("Required"),
    // date_of_birth: Yup.string().required("Required")
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.app);

  useEffect(() => {
    // get the access token from the local storage
    const access = localStorage.getItem("authAccessToken");
    setAccessToken(access);
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const parameters = {
        data: {
          ...values,
          // date_of_birth: format(new Date(values.date_of_birth), "yyyy-MM-dd")
        },
        accessToken: accessToken,
      };
      const res = await dispatch(userCreateAccountStep2(parameters));
      if (res.payload.success === true) {
        // console.log("Step 2 account creation is successful");
        navigate("/mechanic-create-account3");
      }
      else if (res.payload.success === false) {
        const errorMessage = res.payload.message || "An unknown error occurred";
        setErrorMessage(errorMessage); // Set the error message
      }
    } catch (err) {
      // console.error("Create Account failed!:", err);
      const errorMessage = err.res?.message;
      setErrorMessage(errorMessage);
      // Log error message
    } finally {
      setSubmitting(false); // Reset submitting state in Formik
    }
  };

  return (
    <div className="sharp-sans flex flex-col justify-center items-center">
      <div className="mt-10">
        <div className="space-y-1">
          <h3 className="sharp-sans-bold text-2xl">Create Account</h3>
          <p className="sharp-sans text-xs font-medium text-[#878C98]">
            Your journey to accessing and connecting with certified
            <br /> professional mechanics begins here
          </p>
        </div>

        <div className="pt-4 space-y-2">
          <div className="flex justify-between">
            <p className="sharp-sans-bold text-base">
              Enter your personal details
            </p>
            <p className="text-xs text-[#989DB3] font-semibold pt-1">
              Step 2 of 3
            </p>
          </div>
          <div className="">
            <ProgressBar
              completed={67}
              bgColor="#1EB85F"
              height="5px"
              customLabel=" "
              maxCompleted={100}
            />
          </div>
        </div>

        <div className="mt-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className="space-y-[20px]">
                  <FormikControl
                    control="input"
                    type="text"
                    label="First Name"
                    name="first_name"
                    placeholder="Enter your first name"
                    className="!min-w-[330px]"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Last Name"
                    name="last_name"
                    placeholder="Enter your last name"
                    className="!min-w-[330px]"
                  />
                  {/* <div className="relative">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Phone Number"
                                                name="phone_number"
                                                placeholder=""
                                                className="text-center !min-w-[330px]"
                                                
                                                
                                            />
                                            <div className={`flex items-center py-3 space-x-3 absolute left-2 top-[18px]  `}>
                                                <p className="text-xs">+234</p>
                                                <div className="w-[2px] h-[27px] bg-[#D9D9D9] "></div>
                                            </div>
                                        </div>
                                        <div className="relative">
                                            <p className="sharp-sans text-[14px] font-[600] pointer-events-none, max-w-[75%]">Date of Birth</p>
                                            <Field name="date_of_birth">
                                                {({ field, form }) => (
                                                <DatePicker
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={(val) => form.setFieldValue(field.name, val)}
                                                    dateFormat="yyyy/dd/MM"
                                                    label="Date of Birth"
                                                    placeholderText="YY/MM/DD"
                                                    className="!min-w-[330px] mt-1 py-[2px] px-2 text-[14px] rounded-[3px] border border-[#DCDFF1] pr-[42px] min-h-[44px] focus:shadow-[0_0_3px_#E6ECF7] max-h-[44px]"
                                                    showYearDropdown
                                                />
                                                )}
                                            </Field>
                                            <div className="w-auto absolute right-[30px] top-[35px]">
                                                <img
                                                    src={require('../Images/Calendar.png')}    
                                                />
                                            </div>
                                            <ErrorMessage name="dateOfBirth" component={TextError}/>
                                        </div> */}

                  {/* <ErrorMessage name="terms" component={TextError} /> */}
                  {errorMessage && <TextError>{errorMessage}</TextError>}

                  <div className="pt-[5px]">
                    <Button
                      className="bg-greeen text-white !mt-[30px]"
                      type="submit"
                      disable={!formik.isValid || formik.isSubmitting}
                      loading={loading[LoaderKey.USER_CREATE_ACCOUNT_STEP2]?.loading}
                    >
                      {formik.isSubmitting ? "Creating..." : "Continue"}
                    </Button>
                    <Link
                      to="/login-user"
                      className="pt-2 text-center flex justify-center font-[500] text-xs"
                    >
                      Already have an account?{" "}
                      <span className="pl-1 text-greeen sharp-sans-bold">
                        Log In
                      </span>
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MechanicCreateAccount2;
