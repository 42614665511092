
import Damp from "./damp.jsx"
import  {TickCircle} from "iconsax-react"

export default function MechanicScheduled({close}){
    return <div className="pb-16 z-[5] items-center justify-center w-screen absolute flex flex-col gap-4 left-0 bottom-0 bg-white rounded-t-3xl p-4" >
                <div onClick={close} className="z-[-10] absolute top-0 left-0"><Damp/></div>
                <div className="flex justify-center items-center bg-gradient-to-br from-[5%] from-green-200 to-green-500 rounded-full w-[9rem] h-[9rem]"><TickCircle className="text-white w-[70%] h-auto"/></div>
                <div className=" gap-3 w-full flex flex-col items-center justify-center">
                    <p className=" block w-[65%] font-black text-xl text-center" >Your appointment has been scheduled</p>
                    <p className="mb-14 text-center text-slate-600">Hooray! you are one step closer to getting your vehicle back in shape.Your request has been successfully confirmed</p>
                </div>
    </div>
}