
import { SearchNormal, Filter, Calendar } from "iconsax-react"
import mage from "../Images/1 1.png"

export default function TransactionHistory({data,handleSearch,handleChange}){
  const options = ["mechanic","owner","credit","debit"]
  return <div className="p-4 ">
              <Head title={"Transaction history"} handleSearch={handleSearch} options={options} handleChange={handleChange} />
              <HeadLabel/>
              <Transactions data={data} />
  </div>
}

export  function Head({title,handleSearch,placeholder,showDate=true,showFilter=true,handleChange,options}){
  return <div className="flex w-full justify-between ">
    <p className="text-lg font-semibold">{title}</p>
    <Search handleSearch={handleSearch} placeholder={placeholder}/>
    <div className="w-[25%] flex justify-between text-slate-500" >
      {showDate && <DateComp/>}
      {showFilter && <FilterComp handleChange={handleChange} options={options}/>}
    </div>
  </div>
}

function Search({handleSearch,placeholder}){
  return <div className="w-[40%] flex px-3 py-2 border-[0.02rem] border-solid border-slate-500 rounded-lg gap-2">
            <SearchNormal className="text-slate-400"/>
            <input onChange={(e)=>handleSearch(e.target.value)} className="border-none outline-none w-full text-sm " tabIndex={1} placeholder={`${placeholder ||"Search transaction by name or amount"} `}/>
          </div>
}

function DateComp(){
  return <div className="flex text-sm h-fit items-center gap-1 p-2 border-[0.02rem] border-solid border-slate-500 rounded-lg">
        <Calendar/> 
        <select>
          <option >Date</option>
        </select>
  </div>
}

function FilterComp({handleChange,options}){
  return <div className="flex h-fit items-center text-sm gap-1 p-2 border-[0.02rem] border-solid border-slate-500 rounded-lg">
        <Filter/> 
        <select onChange={(e)=>handleChange(e.target.value)}>
          <option >Filter</option>
          {options?.map(function(anOption){
            return <option value={anOption}>{anOption}</option>
          })}
        </select>
  </div>
}

function HeadLabel(){
  return <div className="w-full text-sm text-center text-slate-400 grid grid-cols-5 py-2 bg-slate-100 mt-1 border-slate-400 border-solid border-[0.02rem] rounded-md">
              <p>Date</p>
              <p className="col-span-2">User</p>
              <p>Amount</p>
              <p>Transaction Type</p>
          </div>
}

function Transactions({data=[]}){
  return <div className="mt-2 flex flex-col gap-4 max-h-[70vh] overflow-y-scroll">
            {data.map(function(aTranasaction){
              const value =()=> new Intl.NumberFormat("en-US",{style:"currency",currency:"NGN",signDisplay:"never"}).format(Number(aTranasaction?.amount)).replace("NGN","")
              const date = String(new Date(String(aTranasaction.date_created))).slice(3,16);
              const time  = new Date(String(aTranasaction.date_created)).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit'})
              return <Atransaction occupation={`${aTranasaction.user_detail.account_type}`} username={`${aTranasaction.user_detail.first_name} ${aTranasaction.user_detail.last_name}`} time={time} date={date} amount={value()} isCredit={aTranasaction.transaction_type ==="fund"}/>
            })}
        </div>
}

function Atransaction({date ="",time ="",image="",username="",occupation="",amount="",isCredit=""}){
  return <div className="grid grid-cols-5 w-full justify-items-center items-center text-sm py-3 bg-slate-100 rounded-md ">

      <div className="flex items-center gap-2 ">
        <span className=" flex items-center justify-center w-[2.5rem] h-[2.5rem] p-1 bg-slate-300 rounded-full">
          <Calendar className="text-sm"/>
        </span>
        <div>
          <p className="font-bold">{date}</p>
          <p className="text-slate-500">{time}</p>
        </div>
      </div>

      <div className="flex flex-col items-center gap-2 col-span-2">
          <p className="font-bold">{username}</p>
          <p className="text-slate-500">{occupation}</p>
      </div>

      <span className="flex">₦<p className="font-bold overflow-hidden text-ellipsis">{String(amount).slice(0,amount.length-3)}</p></span>

      {isCredit ? <p className="w-fit px-3 py-2 flex items-center justify-center text-white text-sm bg-green-600 rounded-xl">Credit</p> :  <p className="w-fit px-3 py-2 flex text-white text-sm items-center justify-center bg-red-600 rounded-xl">Debit</p>}
  </div>
}