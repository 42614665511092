import React from "react";
import { ReactComponent as NoRequest } from "../assets/icons/empty-state.icon.svg";
import styled from "styled-components";

export const EmptyState = () => {
  return (
    <Empty>
      <NoRequest />
      <div className="text">
        <h2>No requests yet</h2>
        <p>
          No requests at the moment. Sit back, relax, and enjoy the ride. We'll
          notify you when there's something new to explore or assist you with.
        </p>
      </div>
    </Empty>
  );
};

const Empty = styled.div`
  gap: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 4rem auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .text {
    /* margin-top: -2rem; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: 700;
      color: #8B8B8B;
      line-height: 28.3px;
    }

    p {
      width: 86%;
      font-size: 12px;
      font-weight: 500;
      color: #878C98;
      line-height: 18px;
    }
  }

`;
