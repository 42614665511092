import React from 'react'
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components'

export const Loader = ({ isLoading, children }) => {
  return (
    <LoaderView>
      <div>
        {isLoading ? (
          <Oval color="#1EB85F" height={40} width={40} strokeWidth={4} />
        ) :
        (
          children
        )}
      </div>
    </LoaderView>
  )
}

const LoaderView = styled.div`
  width: 100%;
  height: 100%;
  background-color: '#ffffff';
  overflow-x: hidden;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;