import { useEffect } from "react";
import WalletCard from "./walletCard";
import { ArrowLeft2 } from "iconsax-react";
import WalletTransactions from "./walletTransactions";
import axios from "axios";
import { NavBar } from "../Components/NavBar";
import styled from "styled-components";

export default function MechanicWallet() {
  function handleBack() {
    window.history.back();
  }

  return (
    <WalletView>
      <div className="w-full p-4 min-h-[65vh]">
        <div className="mb-4 flex text-xl">
          <ArrowLeft2 onClick={handleBack} />{" "}
          <p className="font-bold">Wallet</p>
        </div>
        <WalletCard actionText={"Withdraw"} actionLink={"/withdraw"} />
        <WalletTransactions />
      </div>

      <div className="bottom">
        <NavBar />
      </div>
    </WalletView>
  );
}

const WalletView = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .bottom {
    height: 5rem;
    padding: 20px 0;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
    background-color: #ffffff;
    border-top: 1px solid #dedede;
  }
`;
