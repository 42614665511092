import { IoCheckmarkOutline } from "react-icons/io5";
import Button from "../Components/button";
import { Link } from "react-router-dom";

const SuccessVerification = () => {
    return (
        <div className="sharp-sans flex flex-col items-center justify-between h-screen ">
            <div className="flex flex-col justify-center items-center mt-[150px] gap-y-4">
                <div className="relative success-gradient w-[138px] rounded-[100px] h-[138px] border border-grey">
                    <IoCheckmarkOutline className="tick-gradient top-[10px] left-[18px] absolute font-black text-[100px] text-white"/>
                </div>
                <p className="sharp-sans-bold text-center text-xl leading-[26.3px]">
                    Your request to create an<br/> account has been received
                </p>
                <p className="text-center text-xs leading-[15.78px]">
                    Once your credentials have been verified, you will<br/> receive an email confirmation
                </p>
            </div>

            <Link to="/login-user" className="pb-10">
                <Button
                    type="button"
                    text="Finish"
                    className="bg-greeen text-white !min-w-[330px]"
                />
            </Link>
        </div>
    );
}
 
export default SuccessVerification;