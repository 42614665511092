import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { numberWithCommas } from '../utils';
import { format } from 'date-fns';

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export const CallLogs = () => {
  const { email } = useParams();
  const [userCalls, setUserCalls] = useState([]);

  console.log(email);

  const fetchUserCalls = async () => {
    const accessToken = localStorage.getItem("authAccessToken");

    try {
      const response = await axios.get(
        `${baseUrl}/client/user-calls/?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Transaction History Response:", response.data.data);
      setUserCalls(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserCalls();
  }, [email]);

  return (
    <CallsView>
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4 text-left">Caller</th>
              <th className="p-4 text-left">Recipient</th>
              <th className="p-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {userCalls.map((call) => (
              <tr key={call?.id} className="bg-white">
                <td className="p-4 capitalize">{call?.caller?.first_name} {call?.caller?.last_name}</td>
                <td className="p-4 capitalize">{call?.participant?.first_name} {call?.participant?.last_name}</td>
                {/* <td className="p-4">{call?.call_type}</td>
                <td className="p-4">
                  ₦{numberWithCommas(call?.amount || "00.00")}
                </td>
                */}
                <td className="p-4">
                  {format(new Date(call?.created_at), "do MMM, yyyy")}
                </td> 
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CallsView>
  )
}

const CallsView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 2rem;
`;