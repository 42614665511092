import React from 'react'
import styled from 'styled-components';
import { Title } from '../../../Components/shared/Title';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.icon.svg';
import { useNavigate } from 'react-router-dom';

export const ScheduledCalls = () => {
  const navigate = useNavigate();

  return (
    <ScheduledView>
      <div className='back'>
        <button 
          className='back-btn' 
          onClick={() => navigate('/mechanic-dashboard')}
        >
          <ArrowLeft />
        </button>
        <Title text={'Scheduled Calls'} />
      </div>
    </ScheduledView>
  )
}

const ScheduledView = styled.div`
  padding: 1rem;

  .back {
    display: flex;
    align-items: center;
    gap: 1rem;

    .back-btn {
      background: transparent;
      width: 10px;
    }
  }
`;