import React from "react";
import styled from "styled-components";
import { ReactComponent as Home } from "../assets/icons/home.svg";
import { ReactComponent as HomeFilled } from "../assets/icons/home-filled.svg";
import { ReactComponent as Wallet } from "../assets/icons/wallet.svg";
import { ReactComponent as Profile } from "../assets/icons/profile.svg";
import { ReactComponent as ProfileFilled } from "../assets/icons/profile-filled.svg";
import { ReactComponent as Logs } from "../assets/icons/chat.svg";
import { ReactComponent as LogsFilled } from "../assets/icons/chat-filled.svg";
import { useLocation, useNavigate } from "react-router-dom";

export const NavBar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const navlist = [
    {
      title: "Home",
      path: "/mechanic-dashboard",
    },
    {
      title: "Wallet",
      path: "/mechanic-wallet",
    },
    {
      title: "Profile",
      path: "/mechanic-profile",
    },
  ];
  return (
    <Nav>
      <div className="routes">
        {navlist.map((nav, index) => (
          <div
            key={index}
            className={`nav ${pathname === nav.path ? "active" : ""}`}
            onClick={() => navigate(nav.path)}
          >
            {nav.title === "Home" &&
              (pathname === nav.path ? <HomeFilled /> : <Home />)}
            {nav.title === "Wallet" &&
              (pathname === nav.path ? <Wallet /> : <Wallet />)}
            {nav.title === "Profile" &&
              (pathname === nav.path ? <ProfileFilled /> : <Profile />)}
            <p className={`nav ${pathname === nav.path ? "active" : ""}`}>{nav.title}</p>
          </div>
        ))}
      </div>
    </Nav>
  );
};

const Nav = styled.div`
  width: 100vw;
  /* height: 3rem; */
  /* margin: -1rem 0; */
  padding: 0 1rem;
  background-color: #ffffff;
  /* border-top: 1px solid #DEDEDE; */

  .routes {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;
      cursor: pointer;

      p {
        font-size: 0.8rem;

        &.active {
          color: #1EB85F;
        }
      }

      &.active {
        color: #1EB85F;
        border-radius: 10px;
      }
    }
  }
`;
