





export default function CarModel({indicateUnfilled,setResult}){

    return <div className="w-full">
                <p className="mb-1">Car Model</p>
                <input  onChange={(e)=>setResult(init=>e.target.value)} className={`bg-transparent ${indicateUnfilled ? "border-red-700":"border-slate-500"}  border-solid border-[0.02rem] rounded-md py-3 px-2 w-full`} placeholder="Enter Car Model"/>
            </div>
}