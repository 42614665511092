
import { useNavigate } from "react-router-dom"

export default function ChatList({list=[]}){
    return <div className="w-full flex flex-col gap-8">
                {
                    list.map(function(aChatList){
                        return <ChatListItem userImage={aChatList.userImage} userName={aChatList.userName} lastMessage={aChatList.lastMessage} timestamp={aChatList.timestamp} unread={aChatList.unread} />
                    })
                }
    </div>
}

function ChatListItem({id,userImage,userName,lastMessage,timestamp,unread}){
    const navigate = useNavigate();
    function handleClick(){
        navigate("/chat-room");
    }
    return <div onClick={handleClick} className="w-full flex items-center justify-between">
                <img src={userImage} className="w-[10vw] h-full rounded-full" />
                <div className="w-[85%]">
                    <div className="w-full flex justify-between items-center">
                        <p className=" text-ellipsis w-[95%] font-bold overflow-hidden block whitespace-nowrap text-sm">{userName}</p>
                        <p className=" text-slate-400 text-sm">{timestamp}</p>
                    </div>
                    <div className="w-full flex justify-between items-center ">
                        <p className="text-ellipsis text-[12px] w-[85%] overflow-hidden block whitespace-nowrap">{lastMessage}</p>
                        <p className="w-6 h-6 flex justify-center text-white rounded-full bg-red-600 " >{unread}</p>
                    </div>
                </div>
    </div>
}