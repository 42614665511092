const PasswordHintModal = ({isOpen, closeModal}) => {
    if (!isOpen) return null;

    return (
        <div className="rounded-[20px] font-[600] p-2 text-xs mx-auto max-w-[320px] sharp-sans" onClick={closeModal}
            style={{
                backgroundColor: '#e6f7ed',
                position: 'fixed',
                top: '25%',
                
                // transform: "translate(-50%, -50%)",
                zIndex: 1000

            }}
        >
            <div className=" space-y-3 p-3 text-justify">
                <h3 className="sharp-sans-bold text-center">Hint!</h3>
                <p>Passwords must be at least 8 to 12 characters long. Longer passwords are generally more secure.</p>
                <p>Include a mix of uppercase and lowercase letters, numbers, and special characters (e.g. !, @, $, %).</p>
                <p>Avoid using easily guessable passwords, such as common words, phrases, or sequences like 'password', '123456' or 'qwerty.'</p>
            </div>

        </div>
    );
}
 
export default PasswordHintModal;