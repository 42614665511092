import { LoaderKey } from "../../loaderKey";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../slices/appSlice";
import AuthService from "../../service/authService";
import { handleError } from "./utils/errorHandler";

export const loginOwner = createAsyncThunk(
    "loginUser/user",
    async (payload, { dispatch, rejectWithValue }) => {
      dispatch(startLoad(LoaderKey.LOGIN_KEY));
      try {
        const res = await AuthService.loginUser(payload.data);
        const {data} = res;
        //check if the tokens are in the response from the api
        if (data.tokens && data.tokens.access) {
            localStorage.setItem('authAccessToken', data.tokens.access);
            localStorage.setItem('authRefreshToken', data.tokens.refresh);
            localStorage.setItem('userEmail', data.email);
            localStorage.setItem('firstName', data.first_name);
            localStorage.setItem('lastName', data.last_name);
            localStorage.setItem('accountType', data.account_type);

            //add 7 days to the date of login and storing of access tokens and stores it in expiry(i.e, it expires after 7 days)
            const now = new Date();
            const expiry = now.getTime() + (7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

            //storing the expiry date in local storage
            localStorage.setItem("tokenExpiry", expiry);

       
        }
        if (res.success === true) {
          return res;
          // console.log('profile 2 submitted succesfully from the action')
        }
        return rejectWithValue(res);
      } catch (err) {
        // console.log(err, "err");
        const errorData = err.response ? err.response.data : { message: err.message };
        return rejectWithValue(errorData); // Use rejectWithValue to pass error data
  
      } finally {
        // stop loading eventually
        dispatch(stopLoad(LoaderKey.LOGIN_KEY));
      }
    }
  );

  export const rememberMeLogin = createAsyncThunk(
    "rememberLogin/user",
    async ({ data, accessToken }, { dispatch, rejectWithValue }) => {
      dispatch(startLoad(LoaderKey.LOGIN_KEY));
      try {
        const res = await AuthService.rememberMeLogin(data, accessToken);
        
        //check if the tokens are in the response from the api
        if (res.data.tokens && res.data.tokens.access) {
            localStorage.setItem('authAccessToken', data.tokens.access);
            localStorage.setItem('authRefreshToken', data.tokens.refresh);
            localStorage.setItem('userEmail', data.email);
            localStorage.setItem('firstName', data.first_name);
            localStorage.setItem('lastName', data.last_name);
        // logging a success message after this
        // console.log('Login successful from the action!');
        }
        return res;
      } catch (err) {
        // console.log(err, "err");
        return rejectWithValue(err.response ? err.response.data : err.message);
        // if (payload.onError) {
        //   payload.onError(err);
        // }
        // handleError(err, LoaderKey.LOGIN_KEY, dispatch);
      } finally {
        // stop loading eventually
        dispatch(stopLoad(LoaderKey.LOGIN_KEY));
      }
    }
  );

  export const resendOtp = createAsyncThunk(
    "resend/otp",
    async (payload, { dispatch }) => {
      const LOAD_KEY = "resendOtp";
      dispatch(startLoad(LOAD_KEY));
      try {
        const res = await AuthService.resendOtp(payload.data);
        // console.log('Otp resent successfully')

        return res;
      } catch (err) {
        handleError(err, LOAD_KEY, dispatch);;
      } finally {
        // stop loading eventually
        dispatch(stopLoad(LOAD_KEY));
      }
    }

  );

  export const verifyOtp = createAsyncThunk(
    "verify/otp",
    async (payload, { dispatch }) => {
      const LOAD_KEY = "verifyOtp";
      dispatch(startLoad(LOAD_KEY));
      try {
        const res = await AuthService.verifyOtp(payload.data);
        // console.log('Otp verified successfully')

        return res;
      } catch (err) {
        handleError(err, LOAD_KEY, dispatch);
      } finally {
        // stop loading eventually
        dispatch(stopLoad(LOAD_KEY));
      }
    }

  );