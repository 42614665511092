import {MeetingProvider} from "@videosdk.live/react-sdk";
import { AudioControls,Counter,Biodata, AudioMents } from "./components"
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowLeft2 } from "iconsax-react"

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNjY3OTg2NywiZXhwIjoxNzU4MjE1ODY3fQ.o9w3qWNc3Y6caNnq5u6WAsc4Vx1l8sC6Pow9hhZtRnQ"

export default function MechAudioCallz(){
const [startTimer,setStartTimer] = useState(false);
const [searchParams, setSearchParams] = useSearchParams();
const meetingId = searchParams.get("id");
const name = searchParams.get("name");
const image = searchParams.get("image");
const model = searchParams.get("model");
const dur = searchParams.get("val");
const callerToken = searchParams.get("cllrtkn");


function handleBack(){
    window.location.href = "/mechanic-dashboard";
}


    return <div className="w-screen h-screen p-4">
                {(authToken !=null && meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: false, name:name,}} token={authToken}>
                <div className="w-full" onClick={handleBack}><ArrowLeft2/></div>
                <div className="w-full h-[75%] flex flex-col justify-end gap-[8rem] items-center">
                    <div className="flex flex-col gap-[2rem] justify-center  items-center">
                        <Biodata  carmodel={model} image={image} name={name}/>
                        {startTimer && <Counter/>}
                    </div>
                    <AudioControls duration= {dur} meetingId={meetingId} callerToken={callerToken} />
                </div>
                <AudioMents startTimer={function(){setStartTimer(init=>true)}}/>
                </MeetingProvider>)}
    </div>
}
