

import {Switch } from "@mui/material";
import { ArrowLeft2 } from "iconsax-react";
import { useState,useEffect } from "react";
import axios from "axios";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import CircularProgress from "@mui/material/CircularProgress";

const isProd = window.location.hostname === "requestmechanic.com";
const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export default function MechanicAvailability(){
    const days = ["Mon","Tue",'Wed',"Thu",'Fri',"Sat","Sun"];
    const [isActive,setIsActive] = useState(false);
    const [isUpdatingActive,setIsUpdatingActive] = useState(false);
    const [updatingAvailability,setUpdatingAvailability] = useState(false);
    const [removeFirstOption,setRemoveFirstOption] = useState(false);
    const [removeFirstOption1,setRemoveFirstOption1] = useState(false);
    const [fromtime, setFromTime] =useState(dayjs('2024-04-17T9:00'));
    const [totime, setToTime] =useState(dayjs("2024-04-17T17:00"));
    const [fromDay, setFromDay] =useState("0");
    const [toDay, setToDay] =useState("4");
    const [retreivedData,setRetreivedData] = useState(null);

    useEffect(()=>{
        const accessToken = localStorage.getItem("authAccessToken");
    
        if (accessToken) {
            axios.get(
                `${baseUrl}/client/get-availability/`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              ).then(response=>{
                setIsActive(init => response.data?.data?.is_active)
              })
            }
          
      },[])


    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/client/schedule-availability/`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    setRetreivedData(init=>response.data.data);
                    console.log("mechanic availability details",response);
                    return response
                }).catch((error)=>console.log(error))
            }catch{
                console.log("an error occured while fetching profile data")
            }
        }
    },[]);
    function ParseTime(time){
        const StringCast = String(time)
        const processString = StringCast.startsWith(0) ? StringCast.slice(1,2) : StringCast.slice(0,2)
        const numberfied = processString.length == 1 ? Number(processString) : (Number(processString) -12 < 1)? Number(processString) : Number(processString)-12
        const amOrPm = (Number(processString) - 12 < 1) ? "am" : "pm"
        const remainderString = StringCast.slice(2,5);
        return `${numberfied}${remainderString} ${amOrPm}`
    }
    function handleBack(){
        window.history.back()
    }
    function handleUpdateClick(){
        const accessToken = localStorage.getItem('authAccessToken');
        setIsUpdatingActive(init=>true);
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/client/set-availability/${!isActive ?"True":"False"}/`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                  setIsUpdatingActive(init=>false);
                    setIsActive(init=>!isActive);
                    console.log("mechanic profile availability page query response",response);
                    return response
                }).catch((error)=>{
                    console.log(error);
                    setIsUpdatingActive(init=>false)
                    setIsActive(init=>init)});
            }catch(error){
              setIsUpdatingActive(init=>false);
                setIsActive(init=>init);
                console.log("an error occured while fetching profile data",error)
            }
        }
    }
    function handleContinueClick(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
             if((toDay !== null) && (fromDay !== null)){
              setUpdatingAvailability(init=>true)
                try{
                    axios.post(`${baseUrl}/client/schedule-availability/`,{
                        days:days.slice(fromDay,Number(toDay)+1).map(function(one){
                            return one.toLowerCase();
                        }),
                        start_time:fromtime.format("hh:mm"),
                        end_time:totime.format("HH:mm")
                    },{
                        headers:{
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type':'application/json'
                        }
                    }).then(function(response){
                    setUpdatingAvailability(init=>false)
                        console.log(response);
                        return response
                    }).catch((error)=>{
                      setUpdatingAvailability(init=>false)
                      console.log(error)
                    })
                }catch{
                    setUpdatingAvailability(init=>false)
                    console.log("an error occured while fetching profile data")
                }
            }else{
                console.log("some data has not been filled");
            }

        }
    }
    return <div className="w-screen h-screen px-7 py-4">
                <p className="mb-10 font-bold flex gap-3 text-2xl items-center"><ArrowLeft2 onClick={handleBack} className="font-normal"/>Set Availability</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <p className="mb-3 text-slate-400">Set a time and days of the week so your clients can know when you are available</p>
                    <div className="w-full flex flex-col justify-between h-[80vh]">
                        <div className="w-full flex flex-col gap-16">
                            <div className="w-full">
                                <p className="mb-2 font-bold">Days</p>
                                <div className="w-full flex justify-between items-center">
                                    <select    onChange={(e)=> setFromDay(init=>{console.log(e.target.value); return e.target.value })}  className="py-3 px-2 rounded-md block w-[40%] text-slate-400 outline-none border-solid border-slate-500 border-[0.02rem]">
                                        {/* {!removeFirstOption && <option >From</option>} */}
                                        {
                                            days.map(function(aDay,index){
                                                return <option value={index}>{aDay}</option>
                                            })
                                        }
                                    </select>
                                    <p>to</p>
                                    <select onChange={(e)=> setToDay(init=>{console.log(e.target.value); return e.target.value })} className="py-3 px-2 rounded-md block w-[40%] text-slate-400 outline-none border-solid border-slate-500 border-[0.02rem]">
                                        {/* {!removeFirstOption1 && <option></option>} */}
                                        {
                                            days.map(function(aDay,index){
                                                return <option value={index} selected= {index==4} >{aDay}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="mb-2 font-bold">Timeframe</p>
                                <div className="w-full flex justify-between items-center">
                                    <MobileTimePicker ampm="false" value={fromtime}  onChange={(value)=>setFromTime(init=>value)}  style={{fontFamily:"SharpSans"}} sx={{width:"40%"}} />
                                    <p>to</p>
                                    <MobileTimePicker value={totime}   onChange={(value)=>setToTime(init=>value)} style={{fontFamily:"SharpSans"}} sx={{width:"40%"}} />
                                </div>
                            </div>

                            <div>
                                <p className="font-bold pb-4" >Available from {ParseTime(String(retreivedData?.start_time ||9).slice(0,5))} to {ParseTime(String(retreivedData?.end_time ||18).slice(0,5))} on</p>
                                {retreivedData?.days.length > 1 && <div className="flex">
                                    {
                                        retreivedData?.days.map(function(day){
                                            return <span className="p-2 m-1 bg-green-200 rounded-xl ">
                                                        <p className="capitalize">{day}</p>
                                            </span>
                                        })
                                    }
                                </div>}
                                {retreivedData?.days.length < 1 || !retreivedData?.days && <div className="flex">
                                    {
                                       ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"].map(function(day){
                                            return <span className="p-2 m-1 bg-green-200 rounded-xl ">
                                                        <p className="capitalize">{day}</p>
                                            </span>
                                        })
                                    }
                                </div>}
                            </div>

                            <div className="flex justify-between">
                                <p className="font-bold">Active</p>
                                <div className="flex justify-center gap-1">
                                    {isUpdatingActive && <CircularProgress variant="indeterminate" color="success" />}
                                    <Switch checked={isActive} onChange={handleUpdateClick} />
                                </div>
                            </div>
                        </div>
                        <button  onClick={handleContinueClick} className="w-full bg-green-500 text-white p-2 rounded-md">Continue</button>
                    </div>
                </LocalizationProvider>
                {updatingAvailability && <BlurLoader/>}
    </div>
}

function BlurLoader(){
  return <div style={{backgroundColor:"rgba(0,0,0,0.8)"}} className="absolute w-screen h-screen top-0 left-0  flex flex-col justify-center items-center">
            <CircularProgress variant="indeterminate" color="success" />
            <p className="text-white">Updating...</p>
  </div>
}


// import React, { useState } from "react";
// import { Switch } from "@mui/material";
// import axios from "axios";
// import styled from "styled-components";
// import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.icon.svg";
// import { useNavigate } from "react-router-dom";
// import { Title } from "./shared/Title";
// import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

// const isProd = window.location.hostname === "requestmechanic.com";
// const baseUrl = `${
//   isProd
//     ? "https://requestnowmechanic.com/v1"
//     : "https://requestnowmechanic.com/staging/v1"
// }`;

// export default function MechanicAvailability() {
//   const navigate = useNavigate();
//   const [to, setTo] = useState("");
//   const [from, setFrom] = useState("");
//   const [isActive, setIsActive] = useState(false);
//   const [startTime, setStartTime] = useState(dayjs().hour(9).minute(0));
//   // const [startTime, setStartTime] = useState(new Date().setHours(9, 0, 0, 0));
//   const [endTime, setEndTime] = useState(dayjs().hour(17).minute(0));

//   const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

//   const handleFromChange = (e) => {
//     setFrom(e.target.value);
//   };

//   const handleToChange = (e) => {
//     setTo(e.target.value);
//   };

//   const getSelectedDays = () => {
//     const fromIndex = daysOfWeek.indexOf(from);
//     const toIndex = daysOfWeek.indexOf(to);

//     if (fromIndex === -1 || toIndex === -1) return [];

//     // If fromIndex equals toIndex, return the full week starting from that day
//     if (fromIndex === toIndex) {
//       return [
//         ...daysOfWeek.slice(fromIndex),
//         ...daysOfWeek.slice(0, fromIndex + 1),
//       ];
//     }

//     // If fromIndex is less than or equal to toIndex, return the range directly
//     if (fromIndex < toIndex) {
//       return daysOfWeek.slice(fromIndex, toIndex + 1);
//     }

//     // If fromIndex is greater than toIndex, concatenate two slices
//     return [
//       ...daysOfWeek.slice(fromIndex),
//       ...daysOfWeek.slice(0, toIndex + 1),
//     ];
//   };

//   const daysReturned = getSelectedDays();
//   const selectedDays = getSelectedDays();

//   const handleBack = () => {
//     window.history.back();
//   };

//   const formattedStartTime = startTime.format("HH:mm"); // Format as '09:00'
//   const formattedEndTime = endTime.format("HH:mm");

//   const handleSubmit = () => {
//     console.log("Selected Days:", selectedDays);
//     console.log("Start Time:", formattedStartTime);
//     console.log("End Time:", formattedEndTime, typeof formattedEndTime);
//   };

//   const handleContinue = async () => {
//     const accessToken = localStorage.getItem("authAccessToken");
//     const data = {
//       days: selectedDays.map((day) => day.toLowerCase()),
//       start_time: formattedStartTime,
//       end_time: formattedEndTime,
//     };
//     try {
//       const response = await axios.post(
//         `${baseUrl}/client/schedule-availability/`,
//         data,
//         {
//           headers: { Authorization: `Bearer ${accessToken}` },
//         }
//       );
//       navigate("/mechanic-profile");
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const handleToggle = async () => {
//     const accessToken = localStorage.getItem("authAccessToken");
//     const newStatus = !isActive;
//     setIsActive(newStatus);
//     const active_status = newStatus ? "active" : "inactive";

//     try {
//       const response = await axios.get(
//         `${baseUrl}/client/set-availability/${active_status}/`,
//         { headers: { Authorization: `Bearer ${accessToken}` } }
//       );
//       console.log(response);
//     } catch (error) {
//       console.error("Error", error);
//     }
//   };

//   return (
//     <Available>
//       <div className="back">
//         <button className="back-btn" onClick={() => handleBack()}>
//           <ArrowLeft />
//         </button>
//         <Title text={"Set Availability"} />
//       </div>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <p className="subtext">
//           Set a time and days of the week so your clients can know when you're
//           available
//         </p>
//         <div className="wrapper">
//           <div className="column">
//             <div className="breadth">
//               <p>Days</p>
//               <div className="dropdown">
//                 <select value={from} onChange={handleFromChange}>
//                   <option value="">Select a day</option>
//                   {daysOfWeek.map((day) => (
//                     <option key={day} value={day}>
//                       {day}
//                     </option>
//                   ))}
//                 </select>
//                 <p>to</p>
//                 <select value={to} onChange={handleToChange}>
//                   <option value="">Select a day</option>
//                   {daysOfWeek.map((day) => (
//                     <option key={day} value={day}>
//                       {day}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>

//             <div className="breadth">
//               <p>Timeframe</p>
//               <div className="dropdown">
//                 <MobileTimePicker
//                   style={{ fontFamily: "SharpSans" }}
//                   sx={{ width: "45%" }}
//                   value={startTime}
//                   onChange={(newValue) => setStartTime(newValue)}
//                 />
//                 <p>to</p>
//                 <MobileTimePicker
//                   style={{ fontFamily: "SharpSans" }}
//                   sx={{ width: "45%" }}
//                   value={endTime}
//                   onChange={(newValue) => setEndTime(newValue)}
//                 />
//               </div>
//             </div>

//             <div className="horizontal">
//               <p>
//                 Available from {startTime.format("hh:mm A")} to{" "}
//                 {endTime.format("hh:mm A")}
//               </p>

//               <div className="days">
//                 {daysReturned.map((day) => {
//                   return (
//                     <div className="day">
//                       <p>{day}</p>
//                     </div>
//                   );
//                 })}
//               </div>

//               <div className="switch">
//                 <p>Active</p>
//                 <Switch checked={isActive} onChange={handleToggle} />
//               </div>
//             </div>
//           </div>
//           {/* <button onClick={handleSubmit}>Continue</button> */}
//           <button onClick={handleContinue}>Continue</button>
//         </div>
//       </LocalizationProvider>
//     </Available>
//   );
// }

// const Available = styled.div`
//   width: 100%;
//   height: 100%;
//   padding: 1rem;

//   .back {
//     display: flex;
//     align-items: center;
//     gap: 0.6rem;

//     .back-btn {
//       background: transparent;
//       width: 10px;
//     }
//   }

//   .subtext {
//     color: #878c98;
//     margin-bottom: 1rem;
//   }

//   .wrapper {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     height: 80vh;

//     .column {
//       gap: 2rem;
//       width: 100%;
//       display: flex;
//       flex-direction: column;

//       .breadth {
//         width: 100%;
//       }

//       .dropdown {
//         width: 100%;
//         display: flex;
//         margin-top: 1rem;
//         align-items: center;
//         justify-content: space-between;

//         select {
//           padding: 0.7rem 1rem;
//           border-radius: 10px;
//           border: 1px solid #878c98;
//           outline: none;
//         }
//       }

//       .horizontal {
//         margin-top: 2rem;
//         display: flex;
//         flex-direction: column;
//         gap: 2rem;

//         .days {
//           max-width: 80%;
//           display: flex;
//           gap: 0.5rem;
//           flex-wrap: wrap;

//           .day {
//             padding: 0.5rem 1rem;
//             border-radius: 10px;
//             background-color: #eafff8;
//           }
//         }

//         .switch {
//           display: flex;
//           justify-content: space-between;
//         }
//       }
//     }

//     button {
//       color: #ffffff;
//       border-radius: 5px;
//       padding: 0.75rem 1.2rem;
//       background-color: #1eb85f;
//     }
//   }
// `;
