export const LoaderKey = {
    LOGIN_KEY: "loginUser",
    USER_CREATE_ACCOUNT_STEP1: "userCreateAccountStep1",
    USER_CREATE_ACCOUNT_STEP2: "userCreateAccountStep2",
    CAR_OWNER_CREATE_ACCOUNT_STEP3: "carOwnerCreateAccountStep3",
    MECHANIC_CREATE_ACCOUNT_STEP3: "mechanicCreateAccountStep3",
    GET_CAR_BRANDS: "getCarBrands",
    GET_MECHANIC_SERVICES: "getMechanicServices",
    USER_RESET_PASSWORD: "resetPassword",
    USER_SET_NEW_PASSWORD: "setNewPassword",
}