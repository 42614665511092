import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { TickCircle,CloseCircle, Calendar, SecurityTime,ArrowLeft2,CallCalling,Video,Wallet,Clock,Routing2} from "iconsax-react";
import { Alert, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select } from "@mui/material";
import Damp from "./damp.jsx"

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function ScheduledRequest({showNextPage,alldata,address,date,time,closePage}){
    const [fetching,setFetching] = useState(false);
    const [showAlert,setShowAlert] = useState(false);
    const [alertMessage,setAlertMessage] = useState("Sorry no mechanic was found");

    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            setFetching(init=>true);
            axios.post(
                `${baseUrl}/client/match-mechanic/`,
                {
                   ...alldata
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                  })
                  .then((response) => {
                    setFetching(int=>false);
                    showNextPage(init=>true);
                      console.log("match mechanic response",response);
                  })
                  .catch((error) => {
                    setFetching(int=>false);
                    error.response.status === 404 ? setAlertMessage(init=>"no mechanic was found"):setAlertMessage(init=>"An error occured")
                    setShowAlert(init=>true);
                    setTimeout(() => {
                        setShowAlert(init=>false)
                    }, 2000);
                  });
              }else {
                console.error('Authentication credentials were not provided.');
              }
    },[])

    function cancel(){
        closePage();
    }
    return <div className="z-[4] w-screen absolute flex flex-col gap-4 left-0 bottom-0 bg-white rounded-t-3xl p-4" >
                <div onClick={cancel} className="z-[-10] absolute top-0 left-0"><Damp/></div>
                {fetching && <LinearProgress color="success" variant="indeterminate"/>}
                <div>
                    <p className="text-xl mb-2 font-black">Matching you with the mechanic that fits your schedule</p>
                    <p className="mb-2 text-slate-400">Rely on our AI-powered system to lookout for your ideal mechanic that matches your schedule.</p>
                </div>
                {showAlert && <Alert severity="error" style={{fontFamily:"SharpSans"}}>{alertMessage}</Alert>}
                <div className="w-full flex flex-col gap-4">
                    <p className="flex font-bold items-center gap-3"><Routing2 className="text-green-500 w-12 h-12" />{address}</p>
                    <p className="flex font-bold items-center gap-3"><Calendar className="text-green-500 w-8 h-8" />{date}</p>
                    <p className="flex font-bold items-center gap-3"><Clock className="text-green-500 w-8 h-8" />{String(time).slice(0,5)}</p>
                </div>
                <button onClick={()=>cancel()} className=" rounded-sm py-2 w-full text-left flex gap-1 "><CloseCircle className="text-orange-800"/>Cancel Request</button>
    </div>
}