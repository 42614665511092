
import ChatList from "./components/chatList";
import dayjs from "dayjs";
import placeholderImage from "../../Images/1 1.png"

const mockData = [
]

export default function Chat(){
    return <div className="w-Screen h-screen overflow-y-scroll p-4">
                <ChatList list={mockData}/>
    </div>
}