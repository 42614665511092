import { ArrowLeft2 } from "iconsax-react";
import WalletCard from "./walletCard";
import WalletTransactions from "./walletTransactions";

export default function ClientWallet(){
    function handleBack(){
        window.history.back()
    }
    return <div className="w-full p-4 min-h-[65vh]">
                <div className="mb-4 flex text-xl"><ArrowLeft2 onClick={handleBack}/> <p className="font-bold">Wallet</p></div>
                <WalletCard actionText={"Top Up"} actionLink={"/topup"} />
                <WalletTransactions/>
    </div>
}

