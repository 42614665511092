



export default function MatchedMechanic({image,name,isOnline,expertise=[]}){
    return <div className=" z-[3] flex w-full gap-2 items-center">
        <div  className="w-[15vw] h-[15vw] rounded-full overflow-hidden flex justify-center items-center">
            <img src={image} className="w-[120%] h-[120%]" />
        </div>
        <div className="flex flex-col gap-3 w-[70vw]">
            <div className="flex  w-full justify-between">
                <p className="font-bold text-xl">{name}</p>
                <p className="text-slate-600">{isOnline?"online":"offline"}</p>
            </div>
            <div className="flex gap-1 flex-wrap">
                {
                    expertise.map(function(car){
                        return <div className="w-fit max-w-[5rem] h-fit text-sm p-1 rounded-xl bg-teal-100 text-teal-800 text-ellipsis overflow-hidden whitespace-nowrap ">{car}</div>
                    })
                }
            </div>
        </div>
    </div>
}