import { useState,useEffect } from "react";
import { CircularProgress,Switch } from "@mui/material";
import axios from "axios";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}`;

export default function ActiveStatus(){
    const [isUpdatingActive, setIsUpdatingActive] = useState(false);
    const [isActive, setIsActive] = useState(false);

    function handleUpdateClick(){
        const accessToken = localStorage.getItem('authAccessToken');
        setIsUpdatingActive(init=>true);
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/client/set-availability/${!isActive ?"True":"False"}/`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                  setIsUpdatingActive(init=>false);
                    setIsActive(init=>!isActive);
                    console.log("mechanic profile availability page query response",response);
                    return response
                }).catch((error)=>{
                    console.log(error);
                    setIsUpdatingActive(init=>false)
                    setIsActive(init=>init)});
            }catch(error){
              setIsUpdatingActive(init=>false);
                setIsActive(init=>init);
                console.log("an error occured while fetching profile data",error)
            }
        }
    }

    useEffect(()=>{
        const accessToken = localStorage.getItem("authAccessToken");
    
        if (accessToken) {
            axios.get(
                `${baseUrl}/client/get-availability/`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              ).then(response=>{
                setIsActive(init => response.data?.data?.is_active)
              })
            }
          
      },[])

    return <div className="flex justify-between">
                <p className="font-bold">Active</p>
                <div className="flex justify-center gap-1">
                    {isUpdatingActive && <CircularProgress variant="indeterminate" color="success" />}
                    <Switch checked={isActive} onChange={handleUpdateClick} />
                </div>
            </div>
}