import * as Yup from "yup";
import Button from "../../Components/button/index";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormikControl from "../../Components/formik/formikControl";
import { Link, useNavigate } from "react-router-dom";
import { userCreateAccountStep1 } from "../../Components/store/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { LoaderKey } from "../../Components/loaderKey";
import { useEffect, useState } from "react";
import { TextError } from "../../Components/utils";
import ProgressBar from "@ramonak/react-progress-bar"; //the green progress bar
import { loginOwner } from "../../Components/store/actions/authAction";
import { TermsModal } from '../../Components/modal/termsModal';
import PasswordHintModal from "../../Components/modal/passwordHintModal";
import { CiCircleInfo } from "react-icons/ci";

const CarOwnerCreateAcount1 = ({ password, setPassword }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const dispatch = useDispatch(); //Initialize useDispatch hook
  const loading = useSelector((state) => state.app);
  
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [contractType, setContractType] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const openPasswordModal = () => {
    setIsOpen(true);
  };

  const closePasswordModal = () => {
    setIsOpen(false);
  };

  const openModal = (mType, contractType) => {
    setIsModalOpen(true);
    setModalType(mType);
    setContractType((init) => contractType);

    if (mType === "pp") {
      navigate("#privacy-policy");
    } else if (mType === "tnc") {
      navigate("#terms-and-condition");
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalType("");
    setContractType((init) => "");
    navigate(window.location.pathname);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#privacy-policy") {
      openModal("pp");
    } else if (hash === "#terms-and-condition") {
      openModal("tnc");
    }
  }, []);

  // console.log("console");

  const initialValues = {
    email: "",
    password: "",
    terms: false,
  };
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const dataWithAccountType = {
        ...values,
        account_type: "owner",
      };
      setPassword(values.password);
      // console.log('this is the password:' ,password);
      const res = await dispatch(
        userCreateAccountStep1({ data: dataWithAccountType })
      );
      if (res.payload.success === true) {
        localStorage.setItem("CreateAccountEmail", values.email);
        // console.log("Step 1 account creation is successful");
        navigate("/user-otp"); //navigate to car owner verify otp page
      }
      else if (res.payload.success === false) {
        const errorMessage = res.payload.message || "An unknown error occurred";
        setErrorMessage(errorMessage); // Set the error message
      }
      
    } catch (err) {
      const errorMessage = err.res?.message;
      setErrorMessage(errorMessage);
    } finally {
      setSubmitting(false); // Reset submitting state in Formik
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .required("Required")
      .matches(
        /^.{8,}$/,
        "Password must not be less than 8 characters"
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*:;'><.,/?}{[\]\-_+=]).{8,}$/,
        "Please provide a more secure password, refer to the hint to see password requirements."
      ),
    terms: Yup.boolean()
      .oneOf([true], "Please accept the terms and conditions")
      .required("Required"),
  });
  console.log(isOpen)

  return (
    
    <div className={`sharp-sans flex flex-col  items-center ${isOpen && 'modal-background  h-screen '}`}>
      <div className="mt-10">
        <div className="space-y-1">
          <h3 className="sharp-sans-bold text-2xl">Create your Account</h3>
          <p className="sharp-sans text-xs font-medium text-[#878C98]">
            Your journey to accessing and connecting with certified
            <br /> professional mechanics begins here
          </p>
        </div>

        <div className="pt-3 space-y-2">
          <div className="flex justify-between">
            <p className="sharp-sans-bold text-base">
              Setup your login credentials
            </p>
            <p className="text-xs text-[#989DB3] font-semibold pt-1">
              Step 1 of 3
            </p>
          </div>
          <div className="">
            <ProgressBar
              completed={33}
              bgColor="#1EB85F"
              height="5px"
              customLabel=" "
              maxCompleted={100}
            />
          </div>
        </div>
        <div className="mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className="space-y-[20px]">
                  <FormikControl
                    control="input"
                    type="email"
                    label="Email address"
                    name="email"
                    placeholder="Enter your email address"
                    className="!min-w-[345px]"
                  />
                  <div className="">
                    <div className="flex justify-between">
                      <p className="text-[14px] font-[600]">Password</p>
                      <CiCircleInfo className="mr-1" onClick={openPasswordModal} alt="password hint"/>
                    </div>
                    <FormikControl
                      control="input"
                      type="password"
                      label=""
                      name="password"
                      placeholder="Enter your password"
                      className="!min-w-[345px]"
                    />
                    
                  </div>
                  <div className="">
                    <div className="flex justify-between">
                      <p className="text-[14px] font-[600]">Coupon Code</p>
                    </div>
                    <FormikControl
                      control="input"
                      type="text"
                      label=""
                      name="coupon_code"
                      placeholder="Enter a Coupon Code"
                      className="!min-w-[345px]"
                    />
                    
                  </div>
                  
                  

                  <div className="flex mt-[35px] sharp-sans justify-between">
                    <div className="space-x-1 flex">
                      <Field type="checkbox" name="terms" />
                      <p className="text-[##111111] text-xs font-[400]">
                        I agree to the <span><span className="font-[600]" onClick={() => setIsModalOpen(true)}>terms</span> and <span className="font-[600]" onClick={() => setIsModalOpen(true)}>conditions</span></span>
                      </p>
                    </div>
                  </div>
                  <ErrorMessage name="terms" component={TextError} />
                  {errorMessage && <TextError>{errorMessage}</TextError>}
                  {/* <div className="space-y-7">
                    <div className="mt-[30px] inline-flex items-center justify-center w-full">
                      <hr class="min-w-[330px] h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                      <span className="text-xs text-[#989898] absolute px-3 font-[600] text-gray-900 -translate-x-1/2 bg-white left-1/2">
                        or sign up with
                      </span>
                    </div>
                    <div className="flex gap-x-3">
                      <Button className="!min-w-[160px] border-[#DCDFF1] border">
                        <img src={require("../Images/google.png")} />
                        <p className="sharp-sans-bold text-xs ml-1">Google</p>
                      </Button>
                      <Button className="!min-w-[160px] border-[#DCDFF1] border">
                        <img src={require("../Images/facebook.png")} />
                        <p className="-ml-1 sharp-sans-bold text-xs">
                          Facebook
                        </p>
                      </Button>
                    </div>
                  </div> */}

                  <div className="pt-[5px]">
                    <Button
                      className="bg-greeen text-white !mt-[30px]"
                      type="submit"
                      disable={!formik.isValid || formik.isSubmitting}
                      loading={loading[LoaderKey.USER_CREATE_ACCOUNT_STEP1]?.loading}
                    >
                      {formik.isSubmitting ? "Creating..." : "Continue"}
                    </Button>
                    <Link
                      to="/login-user"
                      className="pt-2 text-center flex justify-center font-[500] text-xs"
                    >
                      Already have an account?{" "}
                      <span className="pl-1 text-greeen sharp-sans-bold">
                        Log In
                      </span>
                    </Link>
                  </div>
                  <TermsModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    modalType={modalType}
                    userType={contractType}
                  />
                  
                </Form>
              );
            }}
          </Formik>
          
        </div>
      </div>

      <PasswordHintModal isOpen={isOpen} closeModal={closePasswordModal}/>
    </div>
  );
};

export default CarOwnerCreateAcount1;
