
import { ArrowRight2,ArrowLeft2,Messages1 } from "iconsax-react"
import { Card } from "@mui/material"
import { Link } from "react-router-dom"



export default function GetHelp(){

    function handleBack(){
        window.history.back()
    }

    return <div className="w-screen h-screen px-7 py-4">
        <p className="mb-10 font-bold flex gap-3 text-2xl items-center"><ArrowLeft2 onClick={handleBack} className="font-normal"/>Get Help</p>
        <div className="flex flex-col gap-4">
            <Sub title={"Email"} Icon1={Messages1} />
            <Sub title={"Twitter"}/>
            <Sub title={"Instagram"}/>
        </div>
    </div>
}

function Sub({title,Icon1,link}){
    return <Card sx={{padding:"1.5rem 1rem"}}><div className="flex justify-between" >
     <p className="flex gap-2 justify-between">
         { Icon1 &&<Icon1/>}
         {title}
         </p> 
         <Link to={link}>
             <ArrowRight2/>
         </Link>
         </div>
         </Card>
 }