import { useEffect, useRef, useState } from "react";
import { PeriodConstants, GeneratePeriod,getEachMonthsExpense,getEachDayOfMonthExpense,getEachDayOfWeek } from "./utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
    
  };
  export const data = {
    labels:[],
    smooth:true,
    datasets: [
      {
        fill: true,
        label: '',
        tension:'0',
        data: [70,48,56,70,45,67,34,56,86,55,69,78],
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgba(0,0,0, 0.3)',
      },
    ],

  };


 function TransactionAnalytics({rawData}){
  const [currentQuery,setCurrentQuery] = useState();
  const [Period,setPeriod] = useState(GeneratePeriod(PeriodConstants.aYear));
  const [data,setData] = useState([70,48,56,70,45,67,34,56,86,55,69,78])
  function handleAheaderButtonClick(value){
              setCurrentQuery(init=>value)
  }
  useEffect(function(){
    const nowPeriod = GeneratePeriod(currentQuery ||PeriodConstants.aYear,new Date().getMonth())
    setPeriod(init=>nowPeriod)
    if (currentQuery == PeriodConstants.aWeek) {
      setData(getEachDayOfWeek(rawData))
    }else if(currentQuery == PeriodConstants.aMonth){
      setData(nowPeriod.map(function(aDay,index){ return getEachDayOfMonthExpense(rawData,index+1)}))
    }else{

      setData(Period.map(function(aMonth,index){return getEachMonthsExpense(rawData,index)}))
    }
  },[currentQuery,rawData]);

    return <div className="  w-[65%]  p-4 border-slate-300 bg-white border-[0.02rem] border-solid rounded-xl">
                <Head currentQuery={currentQuery} handleAheaderButtonClick={handleAheaderButtonClick}/>
                <TransactionChart label={Period} data={data} />
            </div>

}

function Head({currentQuery,handleAheaderButtonClick}){

    return <div className=" p-4 flex justify-between">
                <p className="font-semibold">Transaction Analytics</p>
                <div className="flex gap-4">
                    <AHeaderButton handleClick={handleAheaderButtonClick} current={currentQuery} value={PeriodConstants.aYear} />
                    <AHeaderButton handleClick={handleAheaderButtonClick} current={currentQuery} value={PeriodConstants.aMonth} />
                    <AHeaderButton handleClick={handleAheaderButtonClick} current={currentQuery} value={PeriodConstants.aWeek} />
                </div>
    </div>
}

function TransactionChart({label,data}){
    return <Line options={options} data={{labels:label, smooth:true,  datasets: [  {  fill: true,  label: '',  tension:'0.3', data: data, borderColor: 'rgb(0, 0, 0)', backgroundColor: 'rgba(0,0,0, 0.3)',  },  ],  }} />;
}

function AHeaderButton({value,current,handleClick}){
    const buttonRef = useRef(null);

    useEffect(function(){
        if (buttonRef != null ) {
            buttonRef.current.addEventListener("click",function(){
                        handleClick(value)
            })
        }
    },[])

    return <button ref={buttonRef} className={`${current === value ? "bg-slate-200 font-semibold":"bg-transparent"}  p-2 rounded-md`}>{value}</button>
}

export function TotalTransactionVolume({amount,handleChange}){

    const value =(amt)=> new Intl.NumberFormat("en-US",{style:"currency",currency:"NGN",signDisplay:"never",maximumFractionDigits:0}).format(Number(amt)).replace("NGN","")
    return <div className=" landscape:w-[32%] p-4 border-slate-300 bg-white border-[0.02rem] border-solid inline-block rounded-lg">
                <p className="pb-2 text-md text-slate-400">Total Transaction Volume</p>
                <p className="pb-2 text-2xl font-bold">₦{value(amount)}</p>
                <TotalTransactionVolumeFreq handleChange={handleChange}/>
            </div>
}

function TotalTransactionVolumeFreq({handleChange}){
    return <div >
                <select onChange={(e)=>handleChange(e.target.value)} className="outline-transparent text-slate-400">
                    <option value={PeriodConstants.aYear}>This Year</option>
                    <option selected value={PeriodConstants.aMonth} >This Month</option>
                    <option value={PeriodConstants.aWeek}>This Week</option>
                </select>
        </div>
}

export default TransactionAnalytics;



// import React from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// import faker from 'faker';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Filler,
//   Legend
// );

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top' as const,
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Line Chart',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       fill: true,
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

// export function App() {
//   return <Line options={options} data={data} />;
// }
