
import { Map,APIProvider, Pin,AdvancedMarker } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from "axios";
import Menu from "./pwamenu";
import LocationDecision from "../Components/mapcomps/locationComp"
import Urgency from "../Components/mapcomps/urgency"
import ManualLocation from "../Components/mapcomps/manualLocation"
import InstantMatchFound from "../Components/mapcomps/matchFound"
import LowBalance from "../Components/mapcomps/lowBalance"
import Issue from "../Components/mapcomps/issues"
import ScheduledRequest from "../Components/mapcomps/scheduleRequest"
import MechanicScheduled from "../Components/mapcomps/mechanicSchedule"

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function RequestMap(){
    const mapkey = "AIzaSyCGvCxKjSzfDtVS6fxJTUEeUXDI_UaDxGM";
    const mapid = "de861107584712d2";
    const [pinPositionlat,setPinPositionlat] = useState( 6.6413477);
    const [pinPositionlng,setPinPositionlng] = useState(3.3358670);
    const [ManualLocation,setManualLocation] = useState();
    const [AutoLocation,setAutoLocation] = useState();
    const [stringifiedLocation,setstringifiedLocation] = useState('');
    const [profileData, setProfileData] = useState(null);
    const [userCar, setuserCar] = useState(null);

    function updatePin(lattitude,longitude){
        lattitude && setPinPositionlat(init => lattitude);
        longitude && setPinPositionlng(init => longitude);
    }

    const navigate = useNavigate();

    useEffect(function(){
        if (AutoLocation?.lat && AutoLocation?.lng) {
            try{

                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${AutoLocation?.lat},${AutoLocation?.lng}&key=${mapkey}`)
                .then(function(raw){
                   return raw.json()
                }).then(function(result){
                    console.log(result.results[0].formatted_address);
                    setstringifiedLocation(init=>result.results[0].formatted_address);
                })
            }catch{
                console.log("there was an error at auto location effect");
            }
        }
    },[AutoLocation])
    
    useEffect (()=> {
        const accessToken = localStorage.getItem("authAccessToken");
        const tokenExpiry = localStorage.getItem("tokenExpiry");
        const now = new Date().getTime();

        //deletes the access token stored in local storage after 7 days(if now(the present time) > the time from login)
        if (accessToken) {
            if (now > tokenExpiry) {
              localStorage.removeItem("authAccessToken");
              localStorage.removeItem("tokenExpiry");
              navigate("/login-user"); //navigates to login page after deleting
            }
        }

    }, [])

    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        const userEmail = localStorage.getItem('userEmail');
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/user/user/${userEmail}/`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    setProfileData(init=>response.data.data);
                    console.log("profile response",response);
                    return response
                }).catch(function(error){
                    console.log(error);
                })
            }catch{
                console.log("an error occured while fetching profile data")
            }
        }

        if (accessToken) {
            try{
                axios.get(`${baseUrl}/user/registration/step3/owner/`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    setuserCar(init=>response.data.data);
                    console.log("user car  response",response);
                    return response
                }).catch(function(error){
                    console.log(error);
                })
            }catch{
                console.log("an error occured while fetching userCar data")
            }
        }

    },[])

    return <div className="relative w-full min-h-full font-sharp" >
                    <APIProvider apiKey={mapkey} >
                        <Map
                        style={{width:"100vw",height:"100vh"}}
                        defaultCenter={{lat: pinPositionlat , lng: pinPositionlng}}
                        defaultZoom={15}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        mapId={mapid}
                        center={{lat:AutoLocation?.lat || pinPositionlat,lng: AutoLocation?.lng || pinPositionlng}}
                        >
                            <AdvancedMarker draggable position = {{lat:AutoLocation?.lat || pinPositionlat,lng: AutoLocation?.lng || pinPositionlng}}>
                                <Pin  background={"green"} borderColor={"black"} glyphColor={"black"}/>
                            </AdvancedMarker>
                         </Map>
                    </APIProvider> 
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MapOverLays manuaLocationSetter={setstringifiedLocation} location={stringifiedLocation} setAutoLocation={setAutoLocation} updateCoordinates={updatePin}/>
                    </LocalizationProvider>
                    <Menu car={userCar !== null && `${userCar.car_brand?.name} ${userCar.car_model}`} image={profileData !=null && profileData.profile_pic} name={profileData !=null?`${profileData.first_name} ${profileData.last_name}`:""}  />

             </div>

}

function MapOverLays({location,manuaLocationSetter,updateCoordinates, setAutoLocation}){
    const [showUrgency,setShowUrgency] = useState(false);
    const [showIssue,setShowIssue] = useState(false);
    const [showDateNtime,setShowDateNtime] = useState(false);
    const [showManualLocation,setShowManualLocation] = useState(false);
    const [showMatchFound,setShowMatchFound] = useState(false);
    const [showLowBalance,setShowLowBalance] = useState(false);
    const [userSavedCars,setUserSavedCars] = useState([]);
    const [services,setServices] = useState([]);
    const [carBrands,setcarBrands] = useState([]);
    const [issuesData,setIssuesData] = useState(null);
    const [cancleId,setCancleId] = useState();
    const [nearbyMechanicData,setNearbyMechanicData] = useState();
    const [locating,setLocating] = useState(false);
    const [ShowScheduleMatching,setShowScheduleMatching] = useState(false);
    const [showMechanicScheduled,setShowMechanicScheduled] = useState(false);
    const [chosenOne,setChosenOne] = useState();

    useEffect(() => {
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{

                axios
                    .get(`${baseUrl}/client/services/`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    })
                    .then((response) => {
                    console.log("services",response);
                    setServices(init=>response.data.data)
                    })
                    .catch((error) => {
                    console.error('Error fetching transaction history data:', error);
                    });
        }catch{
            console.log("error")
        }

        } else {
          console.error('Authentication credentials were not provided.');
        }

        if (accessToken) {
            try {
                axios
            .get(`${baseUrl}/user/car-brands/`, {
              headers: {
                'Authorization': `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
                console.log("list of car brands",response);
                setcarBrands(init=>response.data.data)
              
            })
            .catch((error) => {
              console.error('Error fetching transaction history data:', error);
            });
            } catch (error) {
                console.log(error);
            }
          
        } else {
          console.error('Authentication credentials were not provided.');
        }

        if (accessToken) {
            try {
                axios
            .get(`${baseUrl}/user/registration/step3/owner/`, {
              headers: {
                'Authorization': `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
                console.log("user saved cars",response);
                setUserSavedCars(init=>response.data.data)
              
            })
            .catch((error) => {
              console.error('Error fetching transaction history data:', error);
            });
            } catch (error) {
             console.log(error);   
            }
          
        } else {
          console.error('Authentication credentials were not provided.');
        }

    }, []);

    useEffect(function(){
        if(nearbyMechanicData?.nearest_mechanics?.length > 1 ){
            const choice = Math.ceil(Math.random() * nearbyMechanicData?.nearest_mechanics?.length);
            setChosenOne(init=>nearbyMechanicData.nearest_mechanics[choice]);
            console.log("choice: ",choice,"chosenOne: ",chosenOne);
        }else if(nearbyMechanicData?.nearest_mechanics?.length == 1){
            setChosenOne(init=>nearbyMechanicData.nearest_mechanics[0]);
        }
        console.log("nearbyMechanicData",nearbyMechanicData);
    },[nearbyMechanicData])
      
    function handleManualFindClick(){
        setShowUrgency(init=>true);
    }
    function handleAutoFindClick(){
        getLocation()
    }
    function getLocation(){
        if (navigator.geolocation) {
            setLocating(init=>true)
            navigator.geolocation.getCurrentPosition(handleLocationSuccess,handleLocationError)
        }else{
            console.log("geolocation is not supported so we cant find you automatically");
        }
    }
    function handleLocationSuccess(position){
        console.log({lat:position.coords.latitude,lng:position.coords.longitude});
            setLocating(init=>false)
           setAutoLocation(init=>{
            return {lat:position.coords.latitude,lng:position.coords.longitude}
           })
           setTimeout(function(){
            setShowUrgency(init=>true)
           },500)
    }
    function handleLocationError(){
        console.log("Error encountered locating you");
    }
    function handleScheduleClick(){
        setShowDateNtime(init=>true);
        setShowIssue(init=>true);
    }
    function handleInstantClick(){
        setShowDateNtime(init=>false);
        setShowIssue(init=>true);
    }
    function handleRequestClick(dataCollected){
        console.log("this is the data collected",dataCollected);
        setIssuesData(init=>dataCollected);
        setShowManualLocation(init=>true);
    }
    
    return <div className=" absolute left-0 bottom-0 w-full ">
                <LocationDecision isLocating={locating} handleBack = {setShowUrgency} shouldamp={showUrgency} auto={handleAutoFindClick} handleManual={handleManualFindClick}/>

                {showUrgency && <Urgency handleScheduleClick={handleScheduleClick} handleBack = {setShowIssue} showdamp={showIssue} handleInstantClick={handleInstantClick}/>}

                {showIssue && <Issue sendCancelId = {setCancleId} usersCars={userSavedCars} carBrands={carBrands} services={services} showdateNtime={showDateNtime} handleCancel={setShowIssue} handleBack={setShowManualLocation}  showdamp={showManualLocation} handleRequestClick={handleRequestClick}/>}

                {showManualLocation && <ManualLocation isScheduledReq={showDateNtime} showNextPage={showDateNtime?setShowScheduleMatching : setShowMatchFound} setMechanicData={setNearbyMechanicData} cancelId={cancleId} issuesData = {issuesData} showDamp={showMatchFound} showNextScreen={setShowMatchFound} locationSetter={manuaLocationSetter} handleCancel = {setShowManualLocation} location={location}/>}

                {ShowScheduleMatching && <ScheduledRequest showNextPage={setShowMechanicScheduled} alldata={nearbyMechanicData} closePage={()=>setShowScheduleMatching(init=>false)} date={nearbyMechanicData.date} time={nearbyMechanicData.time} address={nearbyMechanicData.user_address} />}

                {showMatchFound && <InstantMatchFound calldata={{mechanic_id:nearbyMechanicData?.user?.id,token:nearbyMechanicData?.call_token,email:nearbyMechanicData?.user?.email,model:`${issuesData?.car_model}`}} id={nearbyMechanicData?.id} showLowBlance={setShowLowBalance} showDamp={showLowBalance} showScreen={setShowMatchFound} image={nearbyMechanicData?.profile_pic} name={`${nearbyMechanicData?.user?.first_name} ${nearbyMechanicData?.user?.last_name}`} isOnline={true} expertise={nearbyMechanicData?.user?.car_speciality}/>}
                
                {showLowBalance && <LowBalance handleCancel={setShowLowBalance} />}

                {showMechanicScheduled && <MechanicScheduled close={function(){setShowMechanicScheduled(init=>false)}}/>}
            </div>
}
