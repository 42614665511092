import { useFormikContext } from "formik";
import { useEffect, useState } from "react";


export default function AdressOptions(){
    const {values,setFieldValue} = useFormikContext()
    const [results , setResults] = useState([]);
    const [showAddressDropDown, setshowAddressDropDown] = useState(true);
    const [selectedAddress, setselectedAddress] = useState('');
    const mapKey = "AIzaSyCGvCxKjSzfDtVS6fxJTUEeUXDI_UaDxGM"

    function handleFetch(){
        if (values.workshop_location !== "" ) {        
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${values.workshop_location}&key=${mapKey}`)
            .then(function(raw){
                return raw.json()
            }).then(function(response){
                console.log(response);
                setResults(init=> {
                    if (response.status ==="OK") {
                        return response.results
                    }
                })
            }).catch((error)=>console.log(error))
        }
    }

    function handleAresultClick(address){
        setFieldValue("workshop_location",address);
        setshowAddressDropDown(init=>false);
        setselectedAddress(init=> address);
    }

    useEffect(function(){
        if(selectedAddress.length > 0 && selectedAddress.toLowerCase() !== values.workshop_location.toLowerCase() ){
            setshowAddressDropDown(init=>true)
        }       
        const timeout = setTimeout(() => {
            handleFetch()
        }, 1000);
    
        return ()=> clearTimeout(timeout)
    },[values.workshop_location])

    return showAddressDropDown ? <div className="relative top-[-5%] max-w-[80vw] self-center max-h-[30vh] overflow-y-scroll" >
    
    { results?.map(function(aRes){
            return <Aresult data={aRes} handleClick={handleAresultClick}/>
        })}
    
    { (results?.length === 0 || !results) && <p>no results found</p>}
    
</div>:<div></div>

}


function Aresult({data,handleClick}){
    return <div onClick={()=>handleClick(data.formatted_address)} className="bg-lime-200 w-full px-3 py-2 rounded-md border-[0.02rem] border-black border-solid">
        <p className="w-full overflow-hidden text-ellipsis ">{data.formatted_address}</p>
    </div>
}