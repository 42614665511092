
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";




const navlist = [
    {
      title: "Home",
      link: "/"
    },
    {
      title: "Service",
      link: "#services"
    },
    {
      title: "Support",
      link: "#support"
    },
    {
      title: "FAQs",
      link: "#FAQs"
    }
  ]
export default function NavListHeader({navigationlist = navlist}){
    const path = useLocation().pathname;

    function HandleLinkClick(linkTitle){
      console.log(`${linkTitle} clicked`);
      mixpanel.track('home page Activity',{action:`clicked ${linkTitle} link`});
    }
    return <div className="hidden md:flex items-center gap-4">
                {navigationlist.map((nav) => {
                   return (path !== nav.link) && <a  onClick={()=>{HandleLinkClick(nav.title)}}  key={nav.title} href={nav.link} className="text-gray-400 hover:text-white text-sm no-underline">{nav.title}</a>
                })}
            </div>
}