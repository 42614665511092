import React, { useEffect } from 'react';
import { FiBell } from 'react-icons/fi';
import Ellipse5 from '../Images/Ellipse 5.png';
import mixpanel from "mixpanel-browser";

const TopHeader = ({ userEmail }) => {

  useEffect(function(){
    if (!!userEmail != false) {
      mixpanel.identify(userEmail)
    }
  },[])
  return (
    <div className="top-header flex sticky top-0 left-0 justify-end items-center pr-8">
      <div className="notification relative">
        <div className="notification-icon relative rounded-full border-2 border-gray-100 p-2 cursor-pointer">
          <FiBell className="text-2xl" />
          <div className="notification-badge absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 w-5 h-5 bg-red-400 flex justify-center items-center rounded-full text-white text-xs">
            2
          </div>
        </div>
      </div>
      <div className="divider w-px h-8 bg-gray-300 mx-4"></div>
      <div className="profile flex items-center cursor-pointer">
        <div className="profile-image border-2 border-white rounded-full overflow-hidden">
          <img src={Ellipse5} alt="Profile" className="w-full h-full" />
        </div>
        <div className="profile-info ml-4 flex flex-col">
          <span className="profile-name block text-ellipsis overflow-hidden whitespace-nowrap max-w-[35vw]">{userEmail}</span>
          <span className="profile-role">Owner</span>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
