import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopHeader from './TopHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FiMenu, FiX } from 'react-icons/fi';
import axios from 'axios';
import { Head } from './transactionHistory';
import {CallCalling} from "iconsax-react"

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 



const CallLogs = ({ userEmail }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [callLogs, setCallLogs] = useState([]);
  const [totalCallsData, setTotalCallsData] = useState({
    answered_calls: 0,
    declined_calls: 0,
    ended_calls: 0,
    pending_calls: 0,
    total_calls: 0,
    total_calls_month: 0,
    total_calls_today: 0,
    total_calls_year: 0,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('authAccessToken');

    if (accessToken) {
      // Fetch call logs data
      axios
        .get(`${baseUrl}/stats/all-calls/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        })
        .then(response => {
          console.log('Call Logs Response:', response.data);
          setCallLogs(response.data);
        })
        .catch(error => {
          console.error('Error fetching call logs data:', error);
        });

      // Fetch total calls data
      axios
        .get(`$${baseUrl}/stats/call-statistics/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        })
        .then(response => {
          console.log('Total Calls Response:', response.data);
          setTotalCallsData(response.data);
        })
        .catch(error => {
          console.error('Error fetching total calls data:', error);
        });
    }
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="overall-dashboard-div flex h-screen bg-white-100">
      {/* Hamburger Icon */}
      <div className="hamburger-icon" onClick={toggleSidebar}>
        <FiMenu className="hamburger" />
      </div>

      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

      <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
        <div className={`dashboard-content ${showSidebar ? 'sidebar' : ''}`}>
          {showSidebar && (
            <div className="close-sidebar-button" onClick={toggleSidebar}>
              <FiX className="close-icon" />
            </div>
          )}
          <TopHeader userEmail={userEmail} />

          <div className="p-8">
            <h2 className="dashboard-header text-2xl font-semibold text-gray-800">Call Logs</h2>
            <p className="dashboard-text mt-2 text-gray-400">
              Track and manage the total number of call logs happening on the platform
            </p>
          </div>

          <div className="dashboard-stats grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 ml-8 mr-8">
            <div className="dashboard-stat-container rounded-lg border-gray-100 border p-4 shadow-md">
            <div className="dashboard-stat-icon bg-blue-200 rounded-full p-2 w-fit flex items-center justify-center">
                  <CallCalling  className="text-blue-500 text-2xl" />
            </div>
              <h3 className="mt-2 text-sm text-slate-500 font-semibold">Total Number of Calls</h3>
              <p className="mt-2 text-2xl font-bold">{totalCallsData.total_calls}</p>
            </div>
            {/* Render other summary statistics here */}
          </div>

          <div className="mt-8">
            <div className='px-4 py-2'>
              <Head title={'Call logs'}/>
            </div>
            <div className="overflow-x-auto">
  <table className="min-w-full border border-gray-300">
    <thead>
      <tr>
        <th className="p-4">Date</th>
        <th className="p-4">Caller</th>
        <th className="p-4">Receiver</th>
        <th className="p-4">Duration</th>
      </tr>
    </thead>
    <tbody>
      {callLogs.map(log => (
        <tr key={log.id}>
          <td className="p-4">{log.id}</td>
          <td className="p-4">{log.start_time}</td>
          <td className="p-4">{log.end_time || 'N/A'}</td>
          <td className="p-4">{log.status}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CallLogs;
