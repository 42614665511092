
import { TickCircle, Verify, ArrowRight,EmojiSad,ArrowLeft } from "iconsax-react";
import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';



export function Success(){

    return (
        <div className="w-screen h-[80vh] flex flex-col items-center justify-center gap-4">
            <p className="w-[50%] font-coolvetica font-semibold text-center">Your account has being successfully verified.</p>
            <Verify className="text-2xl w-[5rem] h-[5rem] text-green-500"/>
            <a href={`/login-user`}>
                <Button  className="">Proceed to Login <ArrowRight/> </Button>
            </a>
        </div>
    )
}

export function Failure(){
    return (
        <div className="w-screen h-[80vh] flex flex-col items-center justify-center gap-4">
        <p className="w-[50%] font-coolvetica font-semibold text-center">Something Went Wrong.</p>
        <EmojiSad className="text-2xl w-[5rem] h-[5rem] text-red-500"/>
        <a href={`/login-user`}>
            <Button ><ArrowLeft/> Go Back</Button>
        </a>
        </div>
    )
}

export function Pending(){
    return <div className="w-screen h-[80vh] flex flex-col justify-center items-center">
                <CircularProgress size={"4rem"} variant="indeterminate" sx={{color:"green"}} />
                <p>Verifying...</p>
    </div>
}