import OTPInput from "react-otp-input";
import { useState, useEffect } from "react";
import Button from "../Components/button";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtp, resendOtp } from "../Components/store/actions/authAction";
import { TextError } from "../Components/utils";
import * as Yup from "yup";
import { Bounce, Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
// import { setIsStep1Completed } from "../Components/store/slices/authSlice";
import { loginOwner } from "../Components/store/actions/authAction";
import { Form, Formik, Field, ErrorMessage } from "formik";
import FormikControl from "../Components/formik/formikControl";

const UserOTP = ({ password, setPassword }) => {
  const [otp, setOtp] = useState("");
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const navigate = useNavigate();

  // const { isStep1Completed } = useSelector((state) => state.auth);
  // console.log(isStep1Completed);

  useEffect(() => {
    // Retrieve email from local storage
    const storedEmail = localStorage.getItem("CreateAccountEmail");
    setEmail(storedEmail);
  }, []);

  // const email = useSelector((state) => state.auth);

  const initialValues = {
    otp_code:''
  }
  const validationSchema = Yup.object({
    otp_code: Yup.string()
      .required("Required"),
  });

  const isOtpEmpty = otp.trim().length !== 6;
  const dispatch = useDispatch();

  // console.log(email);

  const handleVerifyOtp = async (values, {setSubmitting}) => {
    try {
      const otpVf = {
        otp_code: values.otp_code,
        email,
      };
      const res = await dispatch(verifyOtp({ data: otpVf }));
      if (res.payload.success === true) {
        // console.log("Otp verified successfully");
        toast("OTP verified successfully");
        // dispatch(setIsStep1Completed(true));

        const loginData = { email, password };
        const loginResponse = await dispatch(loginOwner({ data: loginData }));
        if (loginResponse.payload.success === true) {
          // toast('login endpoint from otp is successful odogwu')
        //   navigate("/carowner-create-account2");
          const accountType = loginResponse.payload.data.account_type;
          // console.log("Login successful. Account type:", accountType);
          if (accountType === "owner") {
            navigate("/carowner-create-account2");
          } else if (accountType === "mechanic") {
            navigate("/mechanic-create-account2");
          }
        }
      } else {
        setErrorMessage("Invalid OTP");
        setIsWrongOtp(true);
      }
      // console.log("Otp verified successfully");
      toast("OTP verified successfully");
      // navigate("/carowner-create-account2");
      // dispatch(setIsStep1Completed());
    } catch (err) {
      console.error("otp verification failed:", err);
      setErrorMessage("Invalid OTP");
      setIsWrongOtp(true);
    } finally {
      // console.log("sucessssss");
      setSubmitting(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = await dispatch(resendOtp({ data: { email } }));
      if (res.payload && res.payload.success) {
        // console.log("Otp sent successfully");
        toast("OTP resent!");
      } else {
        console.error(
          "Failed to resend OTP:",
          res.payload?.message || "Unknown error"
        );
        setErrorMessage("User Verified already, please login");
      }
    } catch (err) {
      console.error("otp verification failed:", err);
    } finally {
      // console.log("sucessssss");
    }
  };

  return (
    <div className="sharp-sans flex flex-col justify-center items-center">
      <div className="flex flex-col">
        <div className="pt-8">
          <div className="space-y-1">
            <h3 className="sharp-sans-bold text-2xl">Create your Account</h3>
            <p className="sharp-sans text-xs font-bold text-black">
              Please enter the OTP sent to your email address
            </p>
          </div>
          <div className="mt-11">
            <Formik 
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleVerifyOtp}
            >
              
              {(formik) =>{
                return (
                  <Form className="flex flex-col h-screen justify-between">
                    <Field name="otp_code">
                      {({ field }) => (
                        <OTPInput 
                          value={field.value}
                          onChange={(otp) => formik.setFieldValue('otp_code', otp)}
                          numInputs={6}
                          shouldAutoFocus={true}
                          placeholder="------"
                          containerStyle="items-center justify-evenly space-x-3 !min-w-[350px]"
                          renderInput={(props) => (
                            <input
                              {...props}
                              className={`${
                                isWrongOtp
                                  ? "border-red-500 transition ease-in-out duration-150 animate-shake rounded-[10px] text-base text-black bg-placeholder border focus:outline-none font-medium min-h-[68px] focus:shadow-[0_0_3px_#0066FF] max-h-[68px] !w-[54px]"
                                  : "border-[#E6ECF7] rounded-[10px] text-base text-black bg-placeholder border focus:outline-none font-medium min-h-[48px] focus:shadow-[0_0_3px_#0066FF] max-h-[48px] !w-[44px]"
                              }`}
                            />
                          )}
                        />
                      )}
                  </Field>
                    {errorMessage && <TextError>{errorMessage}</TextError>}

                    <div className="space-y-5 mb-[150px]">
                    <Button
                      className="bg-greeen text-white !mt-[30px]"
                      type="submit"
                      disable={!formik.isValid || formik.isSubmitting}
                    >
                      {formik.isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                      <Button
                        text="Resend OTP"
                        className="text-xs text-black bg-white"
                        onClick={handleResendOtp}
                      />
                    </div>
                  </Form>
                )
              }}
              
            </Formik>
          </div>
          
          
        </div>
        
      </div>
    </div>
  );
};

export default UserOTP;
