import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { numberWithCommas } from '../utils';
import { format } from 'date-fns';

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export const Requests = () => {
  const { email } = useParams();
  const [userRequest, setUserRequest] = useState([]);

  console.log(email);

  const fetchUserRequest = async () => {
    const accessToken = localStorage.getItem("authAccessToken");

    try {
      const response = await axios.get(
        `${baseUrl}/client/user-requests/?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Transaction History Response:", response.data.data);
      setUserRequest(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserRequest();
  }, [email]);

  return (
    <RequestView>
      <div className="overflow-x-auto">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4 text-left">User</th>
              <th className="p-4 text-left">Mechanic</th>
              <th className="p-4 text-left">Car Brand</th>
              <th className="p-4 text-left">Amount</th>
              <th className="p-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {userRequest.map((request) => (
              <tr key={request?.id} className="bg-white">
                <td className="p-4 capitalize">
                  {request?.user?.first_name || "-"}{" "}
                  {request?.user?.last_name || "-"}
                </td>
                <td className="p-4 capitalize">
                  {request?.mechanic?.first_name || "-"}{" "}
                  {request?.mechanic?.last_name || "-"}
                </td>
                <td className="p-4">{request?.car_brand}</td>
                <td className="p-4">
                  ₦{numberWithCommas(request?.mechanic?.rate || "00.00")}
                </td>
                <td className="p-4">{request?.date || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </RequestView>
  );
}

const RequestView = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;
`;