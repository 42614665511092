import { FiDownload } from "react-icons/fi";
import androidSvg from "../Images/Subtract.svg";
import appleSvg from "../Images/Applelogo.svg";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";


export function AndroidDownloadButton({support_check_timeout,set_support_check_timeout,hasPwaSupport,deferredPrompt,setDeferredPrompt,diagCont,isNotEligibile}) {
//     function handleAndroidDownload(){
//         if(typeof(deferredPrompt?.prompt) === "function"){
//             deferredPrompt?.prompt();
//             setDeferredPrompt(init=>null);
//             mixpanel.track('home page Activity',{action:`clicked on android download button`});
//     }else{
//         diagCont(init=>true)
//         setTimeout(function(){
//             if(typeof(deferredPrompt?.prompt) === "function"){
//                 diagCont(init=>false);
//                 deferredPrompt?.prompt();
//                 setDeferredPrompt(init=>null);
//                 mixpanel.track('home page Activity',{action:`clicked on android download button`});
//             }else{
//                 isNotEligibile(init=>true)
//                 alert("your device is not capable of running this app")
//             }
//         },[15000])
//     }
// }
// const [support_check_timeout,set_support_check_timeout] = useState(false);

useEffect(function(){
    let timeout
    if(!hasPwaSupport){
        timeout = setTimeout(() => {
            set_support_check_timeout(init=>true);
        }, 45000);
    }
    return function(){
        clearTimeout(timeout);
    }
},[hasPwaSupport])

function handleAndroidDownload(){
    if(typeof(deferredPrompt?.prompt) === "function"){
        deferredPrompt?.prompt();
        setDeferredPrompt(init=>null);
        mixpanel.track('home page Activity',{action:`clicked on android download button`});
}else if(support_check_timeout && !hasPwaSupport){
    diagCont(init=>true);
    isNotEligibile(init=>true);
}
else{
    diagCont(init=>true)
    setTimeout(function(){
        if(typeof(deferredPrompt?.prompt) === "function"){
            diagCont(init=>false);
            deferredPrompt?.prompt();
            setDeferredPrompt(init=>null);
            mixpanel.track('home page Activity',{action:`clicked on android download button`});
        }else{
            alert("your device is not capable of running this app")
        }
    },[45000])
}
}
    return (
            <div className="flex outline outline-white self-center text-white rounded-md w-fit py-1 px-2 gap-2 hover:cursor-pointer items-center"
            onClick={()=>{handleAndroidDownload()}}
           >
                <img src={androidSvg} className="h-9" alt="Android Logo" />
                <div>
                    <p className="text-[0.5rem]">Beta version available</p>
                    <p className="font-bold text-lg">Android</p>
                </div>
                {(!hasPwaSupport && !support_check_timeout) && <CircularProgress variant="indeterminate" color="success" />}
                {(hasPwaSupport && !support_check_timeout) && <FiDownload className="ml-2 h-6 w-6" />} {/* Added download icon */}
                {support_check_timeout && <FiDownload className="ml-2 h-6 w-6" />} 
            </div>
    );
}


export function AppleStoreButton() {
    return (
        <a href="https://apps.apple.com/ng/app/requestmechanic/id6475225940"  target="_blank" className="flex justify-center landscape:justify-start">
            <div className="flex outline outline-white self-center text-white rounded-md w-fit py-1 px-2 gap-2 hover:cursor-pointer items-center"
            onClick={()=>{mixpanel.track('home page Activity',{action:`clicked on apple download button`});}}
            >
                <img src={appleSvg} className="h-9" alt="Android Logo" />
                <div>
                <p className="text-[0.5rem]">Beta version Available</p>
                    <p className="font-bold text-lg app-store-text">Apple</p>
                </div>
                <FiDownload className="ml-2 h-6 w-6" /> {/* Added download icon */}
            </div>
        </a>
    );
}